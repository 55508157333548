import React, { Component } from 'react';
import '../App.css';
import edward from '../assets/edward.png';
import lucas from '../assets/lucas.jfif';
import zolo from '../assets/zola.jfif';
import izzy from '../assets/izzy.jpg';
import mod from '../assets/Mod.jpg';
import cmo from '../assets/cmo.jfif';
import andy from '../assets/andy.jpg';

function reveal() {
  var reveals = document.querySelectorAll(".reveal");

  for (var i = 0; i < reveals.length; i++) {
    var windowHeight = window.innerHeight;
    var elementTop = reveals[i].getBoundingClientRect().top;
    var elementVisible = 15;

    if (elementTop < windowHeight - elementVisible) {
      reveals[i].classList.add("active");
    } else {
      reveals[i].classList.remove("active");
    }
  }
}

window.addEventListener("scroll", reveal);


class Team extends Component {

  async componentDidMount() {

  }

  render() {
    return (
      <div class="boxWrap2Team">

        <p class="teamH">Team & Community</p>
        <p class="storyH2">POM LOTTERY CORE TEAMS</p>

        <div class="teamSection">
          <div class="team1">
            <div class="manDiv"><img class="man" src={andy} /></div>
            <div class="tAndName">
              <div class="memName">Mr. Andy LY.meme</div>

            </div>
            <div class="memNamePosition">Founder/CEO</div>
          </div>

          <div class="team1">
            <div class="manDiv"><img class="man" src={cmo} /></div>
            <div class="tAndName">
              <div class="memName">Mr. Erling Brad</div>

            </div>
            <div class="memNamePosition">CMO</div>

          </div>

          <div class="team1">
            <div class="manDiv"><img class="man" src={edward} /></div>
            <div class="tAndName">
              <div class="memName">Mr. Edward</div>

            </div>
            <div class="memNamePosition">Developer</div>

          </div>

        </div>

        <div class="teamSection2">
          <div class="team1">
            <div class="manDiv"><img class="man" src={mod} /></div>
            <div class="tAndName">
              <div class="memName">Mrs. Mod Lead</div>

            </div>
            <div class="memNamePosition">Moderator</div>
          </div>

          <div class="team1">
            <div class="manDiv"><img class="man" src={izzy} /></div>
            <div class="tAndName">
              <div class="memName">Mr. IZZY</div>

            </div>
            <div class="memNamePosition">Graphic Designer/NFT Creator</div>

          </div>

          <div class="team1">
            <div class="manDiv"><img class="man" src={zolo} /></div>
            <div class="tAndName">
              <div class="memName">Mr. Zola</div>

            </div>
            <div class="memNamePosition">Admin</div>

          </div>

          <div class="team1">
            <div class="manDiv"><img class="man" src={lucas} /></div>
            <div class="tAndName">
              <div class="memName">Mr. Lukas</div>

            </div>
            <div class="memNamePosition">Moderator</div>

          </div>

        </div>

        <div class="teamSectionMob">
          <div class="team1">
            <div class="manDiv"><img class="man" src={andy} /></div>
            <div class="tAndName">
              <div class="memName">Mr. Andy LY.meme</div>

            </div>
            <div class="memNamePosition">Founder/CEO</div>
          </div>

          <div class="team1">
            <div class="manDiv"><img class="man" src={cmo} /></div>
            <div class="tAndName">
              <div class="memName">Mr. Erling Brad</div>

            </div>
            <div class="memNamePosition">CMO</div>

          </div>

        </div>

        <div class="teamSection2Mob">
          <div class="team1">
            <div class="manDiv"><img class="man" src={edward} /></div>
            <div class="tAndName">
              <div class="memName">Mr. Edward</div>

            </div>
            <div class="memNamePosition">Developer</div>

          </div>

          <div class="team1">
            <div class="manDiv"><img class="man" src={mod} /></div>
            <div class="tAndName">
              <div class="memName">Mrs. Mod Lead</div>

            </div>
            <div class="memNamePosition">Moderator</div>
          </div>
        </div>

        <div class="teamSection2Mob">


          <div class="team1">
            <div class="manDiv"><img class="man" src={izzy} /></div>
            <div class="tAndName">
              <div class="memName">Mr. IZZY</div>

            </div>
            <div class="memNamePosition">Graphic Designer/NFT Creator</div>

          </div>

          <div class="team1">
            <div class="manDiv"><img class="man" src={zolo} /></div>
            <div class="tAndName">
              <div class="memName">Mr. Zola</div>

            </div>
            <div class="memNamePosition">Admin</div>

          </div>  

        </div>

        <div class="teamSection2Mob">
        <div class="team1">
            <div class="manDiv"><img class="man" src={lucas} /></div>
            <div class="tAndName">
              <div class="memName">Mr. Lukas</div>

            </div>
            <div class="memNamePosition">Moderator</div>

          </div>
        </div>
      </div>
    )
  }
}

export default Team;