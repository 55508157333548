import React, { Component, useEffect } from 'react';
import '../App.css';
import "aos/dist/aos.css";
import Aos from 'aos';
 
const Stry = () => {
    useEffect(() => {
        Aos.init({ duration: 4000 });
    }, [])
}

class Story extends Component {

    render() {
        return (

            <div class="boxWrap2Story">
             
                <p class="storyH">Vision and Mission</p>
                <p class="storyH2">POM LOTTERY <br/> VISION & MISSION</p>

                <div class="aboutVAndM">

            <div>
                    <div class="visionT">VISION</div>

                    <div class="visionBox">
                        <p>- To be the No.01st Lottery Platform in Proof Of Meme Chain</p>
                        <p>- To bring more adoption to Proof Of Meme Chain</p>
                        <p>- To bring the high chance for the Proof Of Meme Community to be the real millionaires</p>
                        <p>- To be the biggest of Charity contribution to the social among Wild West projects </p>
                        <p>- To be the double burn mechanisms for the POM LOTTERY Token</p>                        
                    </div>
                    </div>

                    <div>
                    <div class="visionT">MISSION</div>

                    <div class="missionBox">
                        <p>- To be the top 10th Token among the POM Chain</p>
                        <p>- Created the daily POM LOTTERY winner (Play & Win)</p>
                        <p>- Bring the 1st Meme Show</p>
                        <p>- Bring the 1st Coconut Show</p>
                        <p>- Bring the 1st POMLOT Show</p>      
                        <p>- Bring the 1st Meme Love Show</p>
                        <p>- Make the community crowded/More adoption</p>                  
                        <p>- Bring the unique NFT for the community</p>
                    </div>
                    </div>
                </div>

            </div>
        )
    }
}

export default Story;

