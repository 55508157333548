import React, { Component, useEffect } from 'react';
import '../App.css';
import "aos/dist/aos.css";
import Aos from 'aos';
import chart from '../assets/chart.png';
import mirror from '../assets/mirror.png';
import fire from '../assets/fire.png';
import ladder from '../assets/ladder.png';
import marketing from '../assets/content-marketing.png';

const Stry = () => {
    useEffect(() => {
        Aos.init({ duration: 4000 });
    }, [])
}

class Platform extends Component {

    render() {
        return (

            <div class="boxWrap2Platform">

                <p class="platformH">Lottery Platform, how does it work?</p>
                <p class="storyH2">POM LOTTERY <br /> DECENTRALIZED PLATFORM</p>
                <div class="cs">Lottery Dapp platform will be coming soon !</div>

                <div class="platformMain">
                    <table id="customers">
                        <tr>
                            <th>Buy Lottery</th>
                            <th>Lucky Draw Schedule & Prize Model</th>
                            <th>Winner List</th>
                            <th>NFT Reflection</th>
                            <th>Mint NFT</th>
                            <th>Charity Program</th>
                        </tr>
                        <tr>
                            <td>Tier 1</td>
                            <td>Schedule</td>
                            <td>Daily list of winner</td>
                            <td> </td>
                            <td>Buy/Sell in the POMD</td>
                            <td>Donation achievable</td>

                        </tr>
                        <tr>
                            <td>Tier 2</td>
                            <td>How to do the Lucky draw?</td>
                            <td>Weekly Prize</td>
                            <td> </td>
                            <td>Buy/Sell in the Own Platform</td>
                            <td>Pictures</td>

                        </tr>
                        <tr>
                            <td> </td>
                            <td>How to claim the winning prize!?</td>
                            <td>Contest</td>
                            <td> </td>
                            <td> </td>
                            <td> </td>

                        </tr>
                        <tr>
                            <td> </td>
                            <td>Prize Distribution Model</td>
                            <td> </td>
                            <td> </td>
                            <td> </td>
                            <td> </td>
                        </tr>
                        <tr>
                            <td> </td>
                            <td>No.1 to No. 10</td>
                            <td> </td>
                            <td> </td>
                            <td> </td>
                            <td> </td>
                        </tr>
                        <tr>
                            <td> </td>
                            <td>20000 Winner </td>
                            <td> </td>
                            <td> </td>
                            <td> </td>
                            <td> </td>
                        </tr>

                    </table>

                </div>

                <div class="taxMain">

                    <div class="taxT">POM LOTTERY PLATFORM (POOL PRIZE)</div>

                    <div class="platformMain2">
                        <table id="customers2">
                            <tr>
                                <th>No</th>
                                <th>Lottery Winner</th>
                                <th>Prize by POMLOTTERY</th>
                                <th>Percentage</th>
                            </tr>
                            <tr>
                                <td>1</td>
                                <td>1st Prize</td>
                                <td>200,000,000</td>
                                <td>20%</td>
                            </tr>
                            <tr>
                                <td>2</td>
                                <td>2nd Prize</td>
                                <td>100,000,000</td>
                                <td>10%</td>
                            </tr>
                            <tr>
                                <td>3</td>
                                <td>3rd Prize</td>
                                <td>80,000,000</td>
                                <td>8%</td>
                            </tr>
                            <tr>
                                <td>4</td>
                                <td>4th Prize</td>
                                <td>70,000,000</td>
                                <td>7%</td>
                            </tr>
                            <tr>
                                <td>5</td>
                                <td>5th Prize</td>
                                <td>50,000,000</td>
                                <td>5%</td>
                            </tr>
                            <tr>
                                <td>6</td>
                                <td>6th Prize</td>
                                <td>50,000,000</td>
                                <td>5%</td>
                            </tr>
                            <tr>
                                <td>7</td>
                                <td>7th Prize</td>
                                <td>50,000,000</td>
                                <td>5%</td>
                            </tr>
                            <tr>
                                <td>8</td>
                                <td>8th Prize</td>
                                <td>50,000,000</td>
                                <td>5%</td>
                            </tr>
                            <tr>
                                <td>9</td>
                                <td>9th Prize</td>
                                <td>50,000,000</td>
                                <td>5%</td>
                            </tr>
                            <tr>
                                <td>10</td>
                                <td>10th Prize</td>
                                <td>50,000,000</td>
                                <td>5%</td>
                            </tr>
                            <tr>
                                <td>11</td>
                                <td>1000 Winners Prize $25000</td>
                                <td>250,000,000 </td>
                                <td>25%</td>
                            </tr>
                            <tr>
                                <td></td>
                                <td>TOTAL</td>
                                <td>1,000,000,000</td>
                                <td>100.00%</td>
                            </tr>

                        </table>

                        <div class="tableCon2Main">
                            <div class="tableCon2">
                                <div class="pT2">100% of Total Pools Prize will be distributed back to the community or Players.</div>
                                <p></p>
                                <p>Total Big winner 10 address/day - 75%</p>
                                <p>Total normal winner 1,000 address/day - 25%</p>
                            </div>

                            <div class="tableCon2">
                                <div class="pT2">Tax 10% from the Winners will be distributed as below:</div>
                                <p></p>
                                <p>a. Auto Burn POMLOTTERY - 1%</p>
                                <p>b. Buy Back & Burn - 1%</p>
                                <p>d. NFT Holders Reward Reflection	- 6%</p>
                                <p>e. Platform Fee - 2%</p>
                            </div>
                        </div>
                    </div>

                </div>

                <div class="taxMain">

                    <div class="taxT">POM LOTTERY PLATFORM WINNER LIST</div>

                    <div class="platformMain3">
                        <table id="customers3">
                            <tr>
                                <th>No</th>
                                <th>Winner Address</th>
                                <th>Winner Ticket Number</th>
                                <th>Winner Prize in POM</th>
                                <th>Winner Rank</th>
                            </tr>

                            <tr>
                                <td>1</td>
                                <td>ABC56UKY78</td>
                                <td>289356</td>
                                <td>40,000</td>
                                <td>1</td>

                            </tr>
                            <tr>
                                <td>2</td>
                                <td>XYZ123UKO0</td>
                                <td>345678</td>
                                <td>20,000</td>
                                <td>2</td>
                            </tr>
                            <tr>
                                <td>3</td>
                                <td>GHJ&*^%L)$</td>
                                <td>123456</td>
                                <td>16,000</td>
                                <td>3</td>
                            </tr>
                            <tr>
                                <td>4</td>
                                <td>CXMNB4980</td>
                                <td>987654</td>
                                <td>14,000</td>
                                <td>4</td>
                            </tr>
                            <tr>
                                <td>5</td>
                                <td>098WLHU75</td>
                                <td>234567</td>
                                <td>10,000</td>
                                <td>5</td>
                            </tr>
                            <tr>
                                <td>6</td>
                                <td>000XQL896K</td>
                                <td>999999</td>
                                <td>10,000</td>
                                <td>6</td>
                            </tr>
                            <tr>
                                <td>7</td>
                                <td>XX558HJP0U</td>
                                <td>111893</td>
                                <td>10,000</td>
                                <td>7</td>
                            </tr>
                            <tr>
                                <td>8</td>
                                <td>PPSSX56N85</td>
                                <td>100001</td>
                                <td>10,000</td>
                                <td>8</td>
                            </tr>
                            <tr>
                                <td>9</td>
                                <td>ABCDE5678</td>
                                <td>220938</td>
                                <td>10,000</td>
                                <td>9</td>
                            </tr>
                            <tr>
                                <td>10</td>
                                <td>ASDFG9876</td>
                                <td>327930</td>
                                <td>10,000</td>
                                <td>10</td>
                            </tr>
                            <tr>
                                <td>11</td>
                                <td>LKJH789018</td>
                                <td>667820</td>
                                <td>100,000</td>
                                <td>11</td>
                            </tr>
                        </table>
                        <p class="winnerNote">The daily winner list will be announce everyday on the official telegram group announcement, Twitter, Discord.</p>
                    </div>

                </div>

            </div>
        )
    }
}

export default Platform;

