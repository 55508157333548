import React, { Component, useEffect } from 'react';
import '../App.css';
import "aos/dist/aos.css";
import Aos from 'aos';
import chart from '../assets/chart.png';
import mirror from '../assets/mirror.png';
import fire from '../assets/fire.png';
import ladder from '../assets/ladder.png';
import marketing from '../assets/content-marketing.png';

const Stry = () => {
    useEffect(() => {
        Aos.init({ duration: 4000 });
    }, [])
}

class Tokenomic extends Component {

    render() {
        return (

            <div class="boxWrap2Tokenomic">

                <p class="TokenomicH">Pom Lottery Tokenomic</p>
                <p class="storyH2">POMLOTTERY Ticker: LOTTERY</p>

                <div class="tokenCon">
                    <table>
                        <tr>
                            <th>Total Supply</th>
                            <th>1,000B</th>
                            <th>100%</th>
                        </tr>
                        <tr>
                            <td>Liquidity Pool</td>
                            <td>200B</td>
                            <td>20%</td>
                        </tr>
                        <tr>
                            <td>Private Sale</td>
                            <td>200B</td>
                            <td>20%</td>
                        </tr>
                        <tr>
                            <td>NFT Minters</td>
                            <td>200B</td>
                            <td>20%</td>
                        </tr>
                        <tr>
                            <td>CEX Listing</td>
                            <td>100B</td>
                            <td>10%</td>
                        </tr>
                        <tr>
                            <td>Staking Pool</td>
                            <td>100B</td>
                            <td>10%</td>
                        </tr>
                        <tr>
                            <td>Team Work</td>
                            <td>100B</td>
                            <td>10%</td>
                        </tr>
                        <tr>
                            <td>Airdrop</td>
                            <td>50B</td>
                            <td>5%</td>
                        </tr>
                        <tr>
                            <td>Initial Burn</td>
                            <td>50B</td>
                            <td>5%</td>
                        </tr>
                    </table>

                    <img class="chart" src={chart}/> 
                </div>

                <div class="taxMain">

                <div class="taxT">POM LOTTERY BUY/SALE TAX 7%</div>

                <div class="taxMain2">
                    <div class="utilMainCon">
                        <img src={mirror}/>
                        <p class="utilCon">1% Reflection to holders</p>
                    </div>

                    <div class="utilMainCon">
                        <img src={fire}/>
                        <p class="utilCon">2% Buy Back & Burn</p>
                    </div>

                    <div class="utilMainCon">
                        <img src={ladder}/>
                        <p class="utilCon">2% Added LP</p>
                    </div>

                    <div class="utilMainCon">
                        <img src={marketing}/>
                        <p class="utilCon">2% Marketing & Operation</p>
                    </div>
                </div>

                <div class="taxMain2Mob">
                    <div>
                    <div class="utilMainCon">
                        <img src={mirror}/>
                        <p class="utilCon">1% Reflection to holders</p>
                    </div>

                    <div class="utilMainCon">
                        <img src={fire}/>
                        <p class="utilCon">2% Buy Back & Burn</p>
                    </div>
                    </div>
                    <div>
                    <div class="utilMainCon">
                        <img src={ladder}/>
                        <p class="utilCon">2% Added LP</p>
                    </div>

                    <div class="utilMainCon">
                        <img src={marketing}/>
                        <p class="utilCon">2% Marketing & Operation</p>
                    </div>
                    </div>
                </div>

                </div>

            </div>
        )
    }
}

export default Tokenomic;

