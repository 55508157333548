import React, { Component, useEffect } from 'react';
import '../App.css';
import "aos/dist/aos.css";
import Aos from 'aos';
import champion from '../assets/champion.png';
import nft from '../assets/nft.png';
import growth from '../assets/growth.png';
import love from '../assets/love.png';
import reward from '../assets/reward.png';
import buy from '../assets/online-store.png';
import token from '../assets/token.png';
import deal from '../assets/deal.png';
import add from '../assets/add.png';

const Stry = () => {
    useEffect(() => {
        Aos.init({ duration: 4000 });
    }, [])
}

class Utility extends Component {

    render() {
        return (

            <div class="boxWrap2Utility">

                <p class="utilH">Utilities</p>
                <p class="storyH2">POM LOTTERY <br /> UTILITIES</p>


                <div class="about">
                   
                   <div class="utilMainCon">
                    <img src={champion}/>
                    <p class="utilCon">POM LOTTERY Decentralized Platform (100% Pools Prize contributes back to Players)</p>
                   </div>

                   <div class="utilMainCon">
                    <img src={nft}/>
                    <p class="utilCon">Unique NFT (Meme Show, Coconut Tree Show & POM Show)</p>
                   </div>

                   <div class="utilMainCon">
                    <img src={growth}/>
                    <p class="utilCon">Staking Pools (Stake POM Lottery to earn POM Lottery)</p>
                   </div>

                </div>

                <div class="about">
                   
                   <div class="utilMainCon">
                    <img src={love}/>
                    <p class="utilCon">Social Charity (Asian & Africa)</p>
                   </div>

                   <div class="utilMainCon">
                    <img src={reward}/>
                    <p class="utilCon">Quarterly Pool Prize for the NFT Holders</p>
                   </div>

                   <div class="utilMainCon">
                    <img src={buy}/>
                    <p class="utilCon">Buy the new launch token & contributes to the NFT holders</p>
                   </div>
                   
                </div>

                <div class="about">
                   
                   <div class="utilMainCon">
                    <img src={token}/>
                    <p class="utilCon">POM LOTTERY token reflection to the NFT Holders</p>
                   </div>

                   <div class="utilMainCon">
                    <img src={deal}/>
                    <p class="utilCon">Buy Back & Burn Wild West Projects (Partnership Program)</p>
                   </div>

                   <div class="utilMainCon">
                    <img src={add}/>
                    <p class="utilCon">Much more……………………………</p>
                   </div>
                   
                </div>




                <div class="aboutMob">
                   
                   <div class="utilMainCon">
                    <img src={champion}/>
                    <p class="utilCon">POM LOTTERY Decentralized Platform (100% Pools Prize contributes back to Players)</p>
                   </div>

                   <div class="utilMainCon">
                    <img src={nft}/>
                    <p class="utilCon">Unique NFT (Meme Show, Coconut Tree Show & POM Show)</p>
                   </div>

                </div>

                <div class="aboutMob">
                   
                <div class="utilMainCon">
                    <img src={growth}/>
                    <p class="utilCon">Staking Pools (Stake POM Lottery to earn POM Lottery)</p>
                   </div>

                   <div class="utilMainCon">
                    <img src={love}/>
                    <p class="utilCon">Social Charity (Asian & Africa)</p>
                   </div>
                 
                   
                </div>

                <div class="aboutMob">
                <div class="utilMainCon">
                    <img src={reward}/>
                    <p class="utilCon">Quarterly Pool Prize for the NFT Holders</p>
                   </div>

                   <div class="utilMainCon">
                    <img src={buy}/>
                    <p class="utilCon">Buy the new launch token & contributes to the NFT holders</p>
                   </div>
                   
                   
                </div>

                <div class="aboutMob">
                <div class="utilMainCon">
                    <img src={token}/>
                    <p class="utilCon">POM LOTTERY token reflection to the NFT Holders</p>
                   </div>

                   <div class="utilMainCon">
                    <img src={deal}/>
                    <p class="utilCon">Buy Back & Burn Wild West Projects (Partnership Program)</p>
                   </div>

                </div>

                <div class="aboutMob">                    
                   <div class="utilMainCon">
                    <img src={add}/>
                    <p class="utilCon">Much more……………………………</p>
                   </div>
                </div>

            </div>
        )
    }
}

export default Utility;

