import React, { Component } from 'react';
import '../App.css';
import footPrint2 from '../assets/logoW.png';
import $ from "jquery";
import '../newRoadmap.css'

$(window).on('scroll', function () {
    function isScrollIntoView(elem, index) {
        var docViewTop = $(window).scrollTop();
        var docViewBottom = docViewTop + $(window).height();
        var elemTop = $(elem).offset().top;
        var elemBottom = elemTop + $(window).height() * .5;
        if (elemBottom <= docViewBottom && elemTop >= docViewTop) {
            $(elem).addClass('active');
        }
        if (!(elemBottom <= docViewBottom)) {
            $(elem).removeClass('active');
        }
    }
    var timeline = $('.vertical-scrollable-timeline li');
    Array.from(timeline).forEach(isScrollIntoView);
});

class Roadmap extends Component {


    render() {
        return (

            <div class="boxWrap2Story">

                <p class="storyH">Roadmap</p>
                <p class="storyH2">POM LOTTERY ROADMAP V1.0</p>

                <div class="timeline-container">
                    <ul class="vertical-scrollable-timeline">
                        <li>
                            <h2>06th November 2022</h2>
                            <p>Building Team & Planning</p>
                            <div class="icon-holder">
                                <img class="footPrint2" src={footPrint2} />

                            </div>
                        </li>
                        <li>
                            <h2>06th December 2022</h2>
                            <p>Whitepaper/Roadmap/Private Sale Planning & Telegram/Twitter/Discord</p>
                            <div class="icon-holder">
                                <img class="footPrint2" src={footPrint2} />
                            </div>
                        </li>
                        <li>
                            <h2>08th December 2022</h2>
                            <p>Recruited the Developer/Designer & More moderator</p>
                            <div class="icon-holder">
                                <img class="footPrint2" src={footPrint2} />
                            </div>
                        </li>

                          <li>
                            <h2>10th December 2022</h2>
                            <p>Working on website/NFT/NFT Dapp Minting & Lottery Dapp Platform</p>
                            <div class="icon-holder">
                                <img class="footPrint2" src={footPrint2} />
                            </div>
                        </li>
                        
                        <li>
                            <h2>12th December 2022</h2>
                            <p>Upgrade the Whitepaper & Roadmap/Deploy Smart Contract/Lock tokens</p>
                            <div class="icon-holder">
                                <img class="footPrint2" src={footPrint2} />
                            </div>
                        </li>

                        <li>
                            <h2>16th December 2022</h2>
                            <p>Time 12.00pm UTC start the Private Sale ( 100% add to LP & Burn)</p>
                            <div class="icon-holder">
                                <img class="footPrint2" src={footPrint2} />
                            </div>
                        </li>

                        <li>
                            <h2>16th December 2022</h2>
                            <p>Time 01.00pm UTC start transfer tokens to the private sale buyers</p>
                            <div class="icon-holder">
                                <img class="footPrint2" src={footPrint2} />
                            </div>
                        </li>

                        <li>
                            <h2>16th December 2022</h2>
                            <p>Time 02.00pm UTC Start the Official Launch (POMSWAP)</p>
                            <div class="icon-holder">
                                <img class="footPrint2" src={footPrint2} />
                            </div>
                        </li>
                        <li>
                            <h2>17th December 2022</h2>
                            <p>Live the farming staking pool on the Memetic swap (To be confirm)</p>
                            <div class="icon-holder">
                                <img class="footPrint2" src={footPrint2} />
                            </div>
                        </li>
                        <li>
                            <h2>17th December 2022</h2>
                            <p>Release the website/AMA on POMLOTTERY & Proposed AMA on Wild West</p>
                            <div class="icon-holder">
                                <img class="footPrint2" src={footPrint2} />
                            </div>
                        </li>
                        <li>
                            <h2>18th December 2022</h2>
                            <p>Release Unique NFT Sneak perk & AMA on Wild West</p>
                            <div class="icon-holder">
                                <img class="footPrint2" src={footPrint2} />
                            </div>
                        </li><li>
                            <h2>19th December 2022</h2>
                            <p>Continue marketing on Twitter & call to another channel </p>
                            <div class="icon-holder">
                                <img class="footPrint2" src={footPrint2} />
                            </div>
                        </li>
                        <li>
                            <h2>20th December 2022</h2>
                            <p>Working on to finish the NFT Dapp minting & testnet</p>
                            <div class="icon-holder">
                                <img class="footPrint2" src={footPrint2} />
                            </div>
                        </li>
                        <li>
                            <h2>21th December 2022</h2>
                            <p>Strong marketing activities</p>
                            <div class="icon-holder">
                                <img class="footPrint2" src={footPrint2} />
                            </div>
                        </li>
                        <li>
                            <h2>22th December 2022</h2>
                            <p>Launching NFT Dapp minting & airdrop NFT to private sale buyers</p>
                            <div class="icon-holder">
                                <img class="footPrint2" src={footPrint2} />
                            </div>
                        </li>
                        <li>
                            <h2>22th December 2022</h2>
                            <p>Launching Unique NFT (Meme Show) & AMA on POMLOTTERY telegram</p>
                            <div class="icon-holder">
                                <img class="footPrint2" src={footPrint2} />
                            </div>
                        </li>
                        <li>
                            <h2>25th December 2022</h2>
                            <p>Unlock 20% of total supply to airdrop to NFT minters</p>
                            <div class="icon-holder">
                                <img class="footPrint2" src={footPrint2} />
                            </div>
                        </li>
                        <li>
                            <h2>25th December 2022</h2>
                            <p>30% of fund raised from NFT minting will add to POMLOTTERY LP</p>
                            <div class="icon-holder">
                                <img class="footPrint2" src={footPrint2} />
                            </div>
                        </li>
                        <li>
                            <h2>26th December 2022</h2>
                            <p>Lottery Dapp Platform Testnet & AMA on POMLOTTERY telegram</p>
                            <div class="icon-holder">
                                <img class="footPrint2" src={footPrint2} />
                            </div>
                        </li>
                        <li>
                            <h2>28th December 2022</h2>
                            <p>Launching Lottery Dapp Platform (Meme Show) & AMA on the Twitter</p>
                            <div class="icon-holder">
                                <img class="footPrint2" src={footPrint2} />
                            </div>
                        </li>
                        <li>
                            <h2>28th December 2022</h2>
                            <p>POMLOTTERY call on the 2 big channel (To be confirm) bring new adoption</p>
                            <div class="icon-holder">
                                <img class="footPrint2" src={footPrint2} />
                            </div>
                        </li>
                        <li>
                            <h2>29th December 2022</h2>
                            <p>Daily Lottery Winners Announcement</p>
                            <div class="icon-holder">
                                <img class="footPrint2" src={footPrint2} />
                            </div>
                        </li>
                        <li>
                            <h2>29th December 2022</h2>
                            <p>Mega airdrop to 100,000 wallets on the Twitter Space(Bring new adoption)</p>
                            <div class="icon-holder">
                                <img class="footPrint2" src={footPrint2} />
                            </div>
                        </li>
                        <li>
                            <h2>30th December 2022</h2>
                            <p>Partnership Announcement (TBA)</p>
                            <div class="icon-holder">
                                <img class="footPrint2" src={footPrint2} />
                            </div>
                        </li>
                        <li>
                            <h2>02nd January 2023</h2>
                            <p>Players buy & win the Lottery as normal</p>
                            <div class="icon-holder">
                                <img class="footPrint2" src={footPrint2} />
                            </div>
                        </li>
                        <li>
                            <h2>05th January 2023</h2>
                            <p>1st Buy back/burn POMLOTTERY & Airdrop POMLOTTERY to NFT Holders</p>
                            <div class="icon-holder">
                                <img class="footPrint2" src={footPrint2} />
                            </div>
                        </li>
                        <li>
                            <h2>05th January 2023</h2>
                            <p>NFT holders reward reflection distribution</p>
                            <div class="icon-holder">
                                <img class="footPrint2" src={footPrint2} />
                            </div>
                        </li>
                        <li>
                            <h2>11th January 2023</h2>
                            <p>Unlock Team to 10,000,000,000 & Lock back 1 month (80,000,000,000)</p>
                            <div class="icon-holder">
                                <img class="footPrint2" src={footPrint2} />
                            </div>
                        </li>
                        <li>
                            <h2>12th January 2023</h2>
                            <p>Marketing activities</p>
                            <div class="icon-holder">
                                <img class="footPrint2" src={footPrint2} />
                            </div>
                        </li>
                        <li>
                            <h2>15th January 2023</h2>
                            <p>Showing the Unique NFT Sneak Perk of Coconut Tree Show</p>
                            <div class="icon-holder">
                                <img class="footPrint2" src={footPrint2} />
                            </div>
                        </li>
                        <li>
                            <h2>20th January 2023</h2>
                            <p>Unique NFT launching on POMLOTTERY Dapp minting(Coconut Tree Show)</p>
                            <div class="icon-holder">
                                <img class="footPrint2" src={footPrint2} />
                            </div>
                        </li>
                        <li>
                            <h2>20th January 2023</h2>
                            <p>Launching Lottery Dapp platform for the Coconut Tree Show</p>
                            <div class="icon-holder">
                                <img class="footPrint2" src={footPrint2} />
                            </div>
                        </li>
                        <li>
                            <h2>01st February 2023</h2>
                            <p>1st Social Charity (Place to be confirm)</p>
                            <div class="icon-holder">
                                <img class="footPrint2" src={footPrint2} />
                            </div>
                        </li>
                        <li>
                            <h2>12th February 2023</h2>
                            <p>Listing on Coingekco & Coinmarketcap (POM Chain must be available first)</p>
                            <div class="icon-holder">
                                <img class="footPrint2" src={footPrint2} />
                            </div>
                        </li>
                        <li>
                            <h2>12th February 2023</h2>
                            <p>AMA</p>
                            <div class="icon-holder">
                                <img class="footPrint2" src={footPrint2} />
                            </div>
                        </li>
                        <li>
                            <h2>20th February 2023</h2>
                            <p>Unique NFT launching on POMLOTTERY Dapp minting(POM Show)</p>
                            <div class="icon-holder">
                                <img class="footPrint2" src={footPrint2} />
                            </div>
                        </li>
                        <li>
                            <h2>12th March 2023</h2>
                            <p>Unlock the CEX listing 10% of total supply & Starting CEX listing</p>
                            <div class="icon-holder">
                                <img class="footPrint2" src={footPrint2} />
                            </div>
                        </li>
                        <li>
                            <h2>15th March 2023</h2>
                            <p>Big Quarterly Pool Prize for the NFT Holders (Meme Show)</p>
                            <div class="icon-holder">
                                <img class="footPrint2" src={footPrint2} />
                            </div>
                        </li>
                        <li>
                            <h2>15th March 2023</h2>
                            <p>Big Quarterly Pool Prize for the NFT Holders (Meme Show)</p>
                            <div class="icon-holder">
                                <img class="footPrint2" src={footPrint2} />
                            </div>
                        </li>
                        <li>
                            <h2>16th March 2023</h2>
                            <p>Partnership with Wild West Project</p>
                            <div class="icon-holder">
                                <img class="footPrint2" src={footPrint2} />
                            </div>
                        </li>
                        <li>
                            <h2>18th March 2023</h2>
                            <p>Apply for endorse the partnership from POM Chain</p>
                            <div class="icon-holder">
                                <img class="footPrint2" src={footPrint2} />
                            </div>
                        </li>
                        <li>
                            <h2>---- April to June 2023</h2>
                            <p>To be update on Whitepaper V2.0</p>
                            <div class="icon-holder">
                                <img class="footPrint2" src={footPrint2} />
                            </div>
                        </li>
                        <li>
                            <h2>05th January 2023</h2>
                            <p>1st Buy back/burn POMLOTTERY & Airdrop POMLOTTERY to NFT Holders</p>
                            <div class="icon-holder">
                                <img class="footPrint2" src={footPrint2} />
                            </div>
                        </li>
                        <li>
                            <h2>-----July to Sep 2023</h2>
                            <p>To be update on Whitepaper V3.0</p>
                            <div class="icon-holder">
                                <img class="footPrint2" src={footPrint2} />
                            </div>
                        </li>
                        <li>
                            <h2>To be update on Whitepaper V3.0</h2>
                            <p>To be update on Whitepaper V4.0</p>
                            <div class="icon-holder">
                                <img class="footPrint2" src={footPrint2} />
                            </div>
                        </li>
                        <li>
                            <h2>-----Jan to Dec 2024</h2>
                            <p>To be update on Whitepaper V5.0</p>
                            <div class="icon-holder">
                                <img class="footPrint2" src={footPrint2} />
                            </div>
                        </li>
                       
                    </ul>
                </div>

            </div>


        );

    }
}

export default Roadmap;