import React, { Component, useEffect } from 'react';
import '../App.css';
import "aos/dist/aos.css";
import Aos from 'aos';
import twitter from '../assets/twet.png';
import discord from '../assets/dscrd.png';
import tg from '../assets/tg.png';
import yt from '../assets/yt.png';
import logo from '../assets/logoW.png';

const Stry = () => {
    useEffect(() => {
        Aos.init({ duration: 4000 });
    }, [])
}

class Community extends Component {

    render() {
        return (

            <div class="boxWrap2Tokenomic">

                <div class="lineF"></div>

                <p class="nftH">POM LOTTERY SOCIAL COMMUNITY</p>

                <div class="com">
                    <p>
                    <div>WEBSITE:</div>
                    <div><a href="https://www.pomlottery.com">www.pomlottery.com</a></div>
                    </p>

                    <p>
                    <div>DAPP PLATFORM:</div>
                    <div><a href="https://Pomlottery-dapp.com">Pomlottery-dapp.com</a></div>
                    </p>

                    <p>
                    <div>TELEGRAM GROUP COMMUNITY:</div>
                    <div><a href="#">@Pom Lottery Official</a></div>
                    </p>

                    <p>
                    <div>TELEGRAM ANNOUNCEMENT:</div>
                    <div><a href="#">@PomlotteryAnn</a></div>
                    </p>

                    <p>
                    <div>WITTERS CHANNEL:</div>
                    <div><a href="#">@Pom Lottery Official</a></div>
                    </p>

                    <p>
                    <div>DISCORD:</div>
                    <div>To Be Announce</div>
                    </p>

                    <p>
                    <div>TIKTOK:</div>
                    <div>To Be Announce</div>
                    </p>
                </div>

                <div class="comInfo">
                    <img class="logoW" src={logo} />

                    <div class="media">
                        <img src={twitter} />
                        <img src={discord} />
                        <img src={tg} />
                        <img src={yt} />
                    </div></div>

                    <div class="lineF2"></div>

            </div>
        )
    }
}

export default Community;

