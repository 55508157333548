import React, { Component } from 'react';
import { Routes, Route, BrowserRouter } from 'react-router-dom';
import Home from "./Pages/home.js";
import LotteryPOMG from "./Pages/lotteryPOMG.js";

function App() {

	return (
		<div>
			<BrowserRouter>
				<Routes>

					<Route path='/' element={<Home />} />
					<Route path='/lotteryPOMG' element={<LotteryPOMG />} />

				</Routes>
			</BrowserRouter>
			<div>

			</div>
		</div>

	)
}


export default App;
