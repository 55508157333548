import '../App.css';
import React, { Component } from 'react';
import Story from "../components/story";
import Web3 from "web3";
import Web3Modal from "web3modal";
import WalletConnectProvider from "@walletconnect/web3-provider";
import Roadmap from '../components/roadmap';
import Team from '../components/team';
import logo from '../assets/logo.PNG';
import redBall from '../assets/red.png';
import ball2 from '../assets/blue.png';
import ball3 from '../assets/orange.png';
import Utility from '../components/utility';
import Tokenomic from '../components/tokenomic';
import Platform from '../components/platform';
import NFT from '../components/NFT';
import Community from '../components/community';
import nft2 from '../assets/nft.png';
import Carousel from '../components/carousel';
import BigNumber from 'bignumber.js';
import lottery from '../assets/lottery.png';
import lottery2 from '../assets/lottery2.png';
import lottery3 from '../assets/lottery3.png';
import main from '../assets/main.jpg';

var Scroll = require('react-scroll');

var Link = Scroll.Link;
var DirectLink = Scroll.DirectLink;
var Element = Scroll.Element;
var Events = Scroll.Events;
var scroll = Scroll.animateScroll;
var scrollSpy = Scroll.scrollSpy;

let account;
let mintAmount = 1;
let valueOfNFTs = 0;
let totalSupplyNFT;
let totalSupplyNFT2;
let maxMintNFTs;
let onlyLeft;
let owner;
let publicSale;
let user_input;
let wMintAmount;
let myTokens = [];
let myTokens2 = [];
let adminWhitelistAddresses = [];
let adminPanel = 0;
let adminWhitelistAddresses2 = [];
let maxTokensToBuy = "";
let testingvalue;
let wlMint;
let FinalResult;
let wlMsg = "Mint Now";
let mintStatus = ''
let cost = '';
let maxPerWallet = '';
let publicSaleLimit = '';
let wlClaimed = '';
let new_free_allocation = '';
let revealed = '';
let whitelist_status2 = '';
let baseURI = '';
let public_status = '';
let pkg0;
let pkg1;
let pkg2;
let pkg3;
let pkg4;
let pkg5;
let pkg6;
let drawLottery;
let winners = [];
let records = [];
let lotteryIndex;
let myTickets = [];
let myTicketCount;
let maximumTicketCount;
let ticketCount;
let owner2;
let approved1;
let approved2;
let approved3;
let approved4;
let approved5;
let approved6;
let Records = [];
let allowance;
let cRecords = [];
let cLotteryIndexNumber;
let previousRecords = [];

// 1. Import libraries. Use `npm` package manager to install
const { MerkleTree } = require('merkletreejs');
const keccak256 = require('keccak256');

// 2. Collect list of wallet addresses from competition, raffle, etc.
// Store list of addresses in some data sheeet (Google Sheets or Excel)
let whitelistAddresses = [

];

let whitelistAddresses2 = [];

// 3. Create a new array of `leafNodes` by hashing all indexes of the `whitelistAddresses`
// using `keccak256`. Then creates a Merkle Tree object using keccak256 as the algorithm.
//
// The leaves, merkleTree, and rootHas are all PRE-DETERMINED prior to whitelist claim
const leafNodes = whitelistAddresses.map(addr => keccak256(addr));
const merkleTree = new MerkleTree(leafNodes, keccak256, { sortPairs: true });

const leafNodes2 = whitelistAddresses2.map(addr2 => keccak256(addr2));
const merkleTree2 = new MerkleTree(leafNodes2, keccak256, { sortPairs: true });

// 4. Get root hash of the `merkleeTree` in hexadecimal format (0x)
// Print out the Entire Merkle Tree.
const rootHash = merkleTree.getRoot();
const rootHashHash = merkleTree.getHexRoot();

const rootHash2 = merkleTree2.getRoot();
const rootHashHash2 = merkleTree2.getHexRoot();

const ABI = [
	{
		"inputs": [
			{
				"internalType": "address[]",
				"name": "receiver",
				"type": "address[]"
			},
			{
				"internalType": "uint256[]",
				"name": "quantity",
				"type": "uint256[]"
			}
		],
		"name": "airdrop",
		"outputs": [],
		"stateMutability": "payable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "string",
				"name": "_initBaseURI",
				"type": "string"
			},
			{
				"internalType": "string",
				"name": "_initNotRevealedUri",
				"type": "string"
			},
			{
				"internalType": "string",
				"name": "_contractURI",
				"type": "string"
			}
		],
		"stateMutability": "nonpayable",
		"type": "constructor"
	},
	{
		"inputs": [],
		"name": "ApprovalCallerNotOwnerNorApproved",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "ApprovalQueryForNonexistentToken",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "ApprovalToCurrentOwner",
		"type": "error"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "operator",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "approve",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "ApproveToCaller",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "BalanceQueryForZeroAddress",
		"type": "error"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "quantity",
				"type": "uint256"
			}
		],
		"name": "mint",
		"outputs": [],
		"stateMutability": "payable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "MintToZeroAddress",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "MintZeroQuantity",
		"type": "error"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "operator",
				"type": "address"
			}
		],
		"name": "OperatorNotAllowed",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "OwnerIndexOutOfBounds",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "OwnerQueryForNonexistentToken",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "TokenIndexOutOfBounds",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "TransferCallerNotOwnerNorApproved",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "TransferFromIncorrectOwner",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "TransferToNonERC721ReceiverImplementer",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "TransferToZeroAddress",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "URIQueryForNonexistentToken",
		"type": "error"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "owner",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "address",
				"name": "approved",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "Approval",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "owner",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "address",
				"name": "operator",
				"type": "address"
			},
			{
				"indexed": false,
				"internalType": "bool",
				"name": "approved",
				"type": "bool"
			}
		],
		"name": "ApprovalForAll",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "previousOwner",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "address",
				"name": "newOwner",
				"type": "address"
			}
		],
		"name": "OwnershipTransferred",
		"type": "event"
	},
	{
		"inputs": [],
		"name": "renounceOwnership",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "from",
				"type": "address"
			},
			{
				"internalType": "address",
				"name": "to",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "safeTransferFrom",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "from",
				"type": "address"
			},
			{
				"internalType": "address",
				"name": "to",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			},
			{
				"internalType": "bytes",
				"name": "data",
				"type": "bytes"
			}
		],
		"name": "safeTransferFrom",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_freemintLimit",
				"type": "uint256"
			}
		],
		"name": "set_freemintLimit",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_freemintLimitPerWallet",
				"type": "uint256"
			}
		],
		"name": "set_freemintLimitPerWallet",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_total_PS_limit",
				"type": "uint256"
			}
		],
		"name": "set_total_PS_limit",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_total_wl_limit",
				"type": "uint256"
			}
		],
		"name": "set_total_wl_limit",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "operator",
				"type": "address"
			},
			{
				"internalType": "bool",
				"name": "approved",
				"type": "bool"
			}
		],
		"name": "setApprovalForAll",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "string",
				"name": "_newBaseURI",
				"type": "string"
			}
		],
		"name": "setBaseURI",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "string",
				"name": "_contractURI",
				"type": "string"
			}
		],
		"name": "setContractURI",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_max_per_wallet",
				"type": "uint256"
			}
		],
		"name": "setMax_per_wallet",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_MAX_SUPPLY",
				"type": "uint256"
			}
		],
		"name": "setMAX_SUPPLY",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "string",
				"name": "_notRevealedURI",
				"type": "string"
			}
		],
		"name": "setNotRevealedURI",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_publicSaleCost",
				"type": "uint256"
			}
		],
		"name": "setPublicSaleCost",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "_receiver",
				"type": "address"
			},
			{
				"internalType": "uint96",
				"name": "_royaltyFeesInBips",
				"type": "uint96"
			}
		],
		"name": "setRoyaltyInfo",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_specialNFTHoldersCost",
				"type": "uint256"
			}
		],
		"name": "setspecialNFTHoldersCost",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_whitelistCost",
				"type": "uint256"
			}
		],
		"name": "setWhitelistCost",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "bytes32",
				"name": "newWhitelistSigner",
				"type": "bytes32"
			}
		],
		"name": "setWhitelistSigner",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "quantity",
				"type": "uint256"
			}
		],
		"name": "specialMint",
		"outputs": [],
		"stateMutability": "payable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "toggle_paused",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "toggle_public_mint_status",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "toggle_special_mint_status",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "toggle_wl_mint_status",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "toggleReveal",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "from",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "address",
				"name": "to",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "Transfer",
		"type": "event"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "from",
				"type": "address"
			},
			{
				"internalType": "address",
				"name": "to",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "transferFrom",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "newOwner",
				"type": "address"
			}
		],
		"name": "transferOwnership",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "bytes32[]",
				"name": "_proof",
				"type": "bytes32[]"
			},
			{
				"internalType": "uint256",
				"name": "quantity",
				"type": "uint256"
			}
		],
		"name": "whitelistMint",
		"outputs": [],
		"stateMutability": "payable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "withdraw",
		"outputs": [],
		"stateMutability": "payable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "owner",
				"type": "address"
			}
		],
		"name": "balanceOf",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "baseURI",
		"outputs": [
			{
				"internalType": "string",
				"name": "",
				"type": "string"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "contractURI",
		"outputs": [
			{
				"internalType": "string",
				"name": "",
				"type": "string"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "freemintCount",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "freemintLimit",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "freemintLimitPerWallet",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "getApproved",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "owner",
				"type": "address"
			},
			{
				"internalType": "address",
				"name": "operator",
				"type": "address"
			}
		],
		"name": "isApprovedForAll",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "max_per_wallet",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"name": "mintedFree",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "name",
		"outputs": [
			{
				"internalType": "string",
				"name": "",
				"type": "string"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "notRevealedUri",
		"outputs": [
			{
				"internalType": "string",
				"name": "",
				"type": "string"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "OPERATOR_FILTER_REGISTRY",
		"outputs": [
			{
				"internalType": "contract IOperatorFilterRegistry",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "owner",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "ownerOf",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "paused",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "public_mint_status",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"name": "publicMinted",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "publicSaleCost",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "revealed",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_tokenId",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "_salePrice",
				"type": "uint256"
			}
		],
		"name": "royaltyInfo",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "special_mint_status",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "specialNFTHoldersCost",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "bytes4",
				"name": "interfaceId",
				"type": "bytes4"
			}
		],
		"name": "supportsInterface",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "symbol",
		"outputs": [
			{
				"internalType": "string",
				"name": "",
				"type": "string"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "index",
				"type": "uint256"
			}
		],
		"name": "tokenByIndex",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "owner",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "index",
				"type": "uint256"
			}
		],
		"name": "tokenOfOwnerByIndex",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "tokenURI",
		"outputs": [
			{
				"internalType": "string",
				"name": "",
				"type": "string"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "total_PS_count",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "total_PS_limit",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "total_wl_count",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "total_wl_limit",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "totalSupply",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "whitelistCost",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "whitelistSigner",
		"outputs": [
			{
				"internalType": "bytes32",
				"name": "",
				"type": "bytes32"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "wl_mint_status",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"name": "wlMinted",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	}
];

const address = "0x513f478190Efa3ff9a20b1BC197df579D6C8c1Bc";

//......................NEW CONTRACT................................//

const lotteryABI = [
	{
		"type": "constructor",
		"stateMutability": "nonpayable",
		"inputs": []
	},
	{
		"type": "event",
		"name": "OwnershipTransferred",
		"inputs": [
			{
				"type": "address",
				"name": "previousOwner",
				"internalType": "address",
				"indexed": true
			},
			{
				"type": "address",
				"name": "newOwner",
				"internalType": "address",
				"indexed": true
			}
		],
		"anonymous": false
	},
	{
		"type": "function",
		"stateMutability": "view",
		"outputs": [
			{
				"type": "address",
				"name": "",
				"internalType": "address"
			}
		],
		"name": "burnWallet",
		"inputs": []
	},
	{
		"type": "function",
		"stateMutability": "view",
		"outputs": [
			{
				"type": "uint256",
				"name": "",
				"internalType": "uint256"
			}
		],
		"name": "burnWalletTax",
		"inputs": []
	},
	{
		"type": "function",
		"stateMutability": "view",
		"outputs": [
			{
				"type": "address",
				"name": "",
				"internalType": "address"
			}
		],
		"name": "buyBack",
		"inputs": []
	},
	{
		"type": "function",
		"stateMutability": "view",
		"outputs": [
			{
				"type": "uint256",
				"name": "",
				"internalType": "uint256"
			}
		],
		"name": "buyBackTax",
		"inputs": []
	},
	{
		"type": "function",
		"stateMutability": "view",
		"outputs": [
			{
				"type": "uint256",
				"name": "",
				"internalType": "uint256"
			}
		],
		"name": "decimals",
		"inputs": []
	},
	{
		"type": "function",
		"stateMutability": "payable",
		"outputs": [],
		"name": "deposit",
		"inputs": []
	},
	{
		"type": "function",
		"stateMutability": "view",
		"outputs": [
			{
				"type": "uint256",
				"name": "",
				"internalType": "uint256"
			}
		],
		"name": "depositedAmounts",
		"inputs": [
			{
				"type": "uint256",
				"name": "",
				"internalType": "uint256"
			}
		]
	},
	{
		"type": "function",
		"stateMutability": "nonpayable",
		"outputs": [],
		"name": "distributePrize",
		"inputs": []
	},
	{
		"type": "function",
		"stateMutability": "view",
		"outputs": [
			{
				"type": "uint256",
				"name": "",
				"internalType": "uint256"
			}
		],
		"name": "drawCount",
		"inputs": []
	},
	{
		"type": "function",
		"stateMutability": "nonpayable",
		"outputs": [],
		"name": "drawLottery",
		"inputs": []
	},
	{
		"type": "function",
		"stateMutability": "view",
		"outputs": [
			{
				"type": "uint256",
				"name": "",
				"internalType": "uint256"
			}
		],
		"name": "getLotteryIndex",
		"inputs": []
	},
	{
		"type": "function",
		"stateMutability": "view",
		"outputs": [
			{
				"type": "tuple",
				"name": "",
				"internalType": "struct POMGLotteryDraw.Records",
				"components": [
					{
						"type": "uint256",
						"name": "drawNumber",
						"internalType": "uint256"
					},
					{
						"type": "address",
						"name": "walletAddress",
						"internalType": "address"
					},
					{
						"type": "uint256",
						"name": "prize",
						"internalType": "uint256"
					},
					{
						"type": "uint256",
						"name": "winningTicketNumber",
						"internalType": "uint256"
					},
					{
						"type": "uint256",
						"name": "lotteryIndex",
						"internalType": "uint256"
					}
				]
			}
		],
		"name": "getRecord",
		"inputs": [
			{
				"type": "uint256",
				"name": "index",
				"internalType": "uint256"
			}
		]
	},
	{
		"type": "function",
		"stateMutability": "view",
		"outputs": [
			{
				"type": "tuple[]",
				"name": "",
				"internalType": "struct POMGLotteryDraw.Records[]",
				"components": [
					{
						"type": "uint256",
						"name": "drawNumber",
						"internalType": "uint256"
					},
					{
						"type": "address",
						"name": "walletAddress",
						"internalType": "address"
					},
					{
						"type": "uint256",
						"name": "prize",
						"internalType": "uint256"
					},
					{
						"type": "uint256",
						"name": "winningTicketNumber",
						"internalType": "uint256"
					},
					{
						"type": "uint256",
						"name": "lotteryIndex",
						"internalType": "uint256"
					}
				]
			}
		],
		"name": "getRecords",
		"inputs": []
	},
	{
		"type": "function",
		"stateMutability": "view",
		"outputs": [
			{
				"type": "uint256",
				"name": "",
				"internalType": "uint256"
			}
		],
		"name": "getTicketCount",
		"inputs": []
	},
	{
		"type": "function",
		"stateMutability": "view",
		"outputs": [
			{
				"type": "uint256",
				"name": "",
				"internalType": "uint256"
			}
		],
		"name": "lotteryIndex",
		"inputs": []
	},
	{
		"type": "function",
		"stateMutability": "nonpayable",
		"outputs": [],
		"name": "manualNormalPrizeDistribute",
		"inputs": [
			{
				"type": "address",
				"name": "manualWinnerAddress",
				"internalType": "address"
			},
			{
				"type": "uint256",
				"name": "position",
				"internalType": "uint256"
			}
		]
	},
	{
		"type": "function",
		"stateMutability": "view",
		"outputs": [
			{
				"type": "uint256",
				"name": "",
				"internalType": "uint256"
			}
		],
		"name": "maximumTicketCount",
		"inputs": []
	},
	{
		"type": "function",
		"stateMutability": "view",
		"outputs": [
			{
				"type": "uint256",
				"name": "",
				"internalType": "uint256"
			}
		],
		"name": "minimumTicketCount",
		"inputs": []
	},
	{
		"type": "function",
		"stateMutability": "view",
		"outputs": [
			{
				"type": "uint256",
				"name": "",
				"internalType": "uint256"
			}
		],
		"name": "myTicketCount",
		"inputs": [
			{
				"type": "address",
				"name": "",
				"internalType": "address"
			},
			{
				"type": "uint256",
				"name": "",
				"internalType": "uint256"
			}
		]
	},
	{
		"type": "function",
		"stateMutability": "view",
		"outputs": [
			{
				"type": "uint256",
				"name": "",
				"internalType": "uint256"
			}
		],
		"name": "myTickets",
		"inputs": [
			{
				"type": "address",
				"name": "",
				"internalType": "address"
			},
			{
				"type": "uint256",
				"name": "",
				"internalType": "uint256"
			},
			{
				"type": "uint256",
				"name": "",
				"internalType": "uint256"
			}
		]
	},
	{
		"type": "function",
		"stateMutability": "view",
		"outputs": [
			{
				"type": "address",
				"name": "",
				"internalType": "address"
			}
		],
		"name": "nftHoldersReflection",
		"inputs": []
	},
	{
		"type": "function",
		"stateMutability": "view",
		"outputs": [
			{
				"type": "uint256",
				"name": "",
				"internalType": "uint256"
			}
		],
		"name": "nftHoldersReflectionTax",
		"inputs": []
	},
	{
		"type": "function",
		"stateMutability": "view",
		"outputs": [
			{
				"type": "address",
				"name": "",
				"internalType": "address"
			}
		],
		"name": "owner",
		"inputs": []
	},
	{
		"type": "function",
		"stateMutability": "nonpayable",
		"outputs": [],
		"name": "pkg0",
		"inputs": []
	},
	{
		"type": "function",
		"stateMutability": "nonpayable",
		"outputs": [],
		"name": "pkg1",
		"inputs": []
	},
	{
		"type": "function",
		"stateMutability": "nonpayable",
		"outputs": [],
		"name": "pkg2",
		"inputs": []
	},
	{
		"type": "function",
		"stateMutability": "view",
		"outputs": [
			{
				"type": "address",
				"name": "",
				"internalType": "address"
			}
		],
		"name": "platform",
		"inputs": []
	},
	{
		"type": "function",
		"stateMutability": "view",
		"outputs": [
			{
				"type": "uint256",
				"name": "",
				"internalType": "uint256"
			}
		],
		"name": "platformTax",
		"inputs": []
	},
	{
		"type": "function",
		"stateMutability": "view",
		"outputs": [
			{
				"type": "uint256",
				"name": "",
				"internalType": "uint256"
			}
		],
		"name": "prize100",
		"inputs": []
	},
	{
		"type": "function",
		"stateMutability": "view",
		"outputs": [
			{
				"type": "uint256",
				"name": "",
				"internalType": "uint256"
			}
		],
		"name": "prize101",
		"inputs": []
	},
	{
		"type": "function",
		"stateMutability": "view",
		"outputs": [
			{
				"type": "uint256",
				"name": "",
				"internalType": "uint256"
			}
		],
		"name": "prize102",
		"inputs": []
	},
	{
		"type": "function",
		"stateMutability": "view",
		"outputs": [
			{
				"type": "uint256",
				"name": "",
				"internalType": "uint256"
			}
		],
		"name": "prize103",
		"inputs": []
	},
	{
		"type": "function",
		"stateMutability": "view",
		"outputs": [
			{
				"type": "uint256",
				"name": "",
				"internalType": "uint256"
			}
		],
		"name": "prize104",
		"inputs": []
	},
	{
		"type": "function",
		"stateMutability": "view",
		"outputs": [
			{
				"type": "uint256",
				"name": "",
				"internalType": "uint256"
			}
		],
		"name": "prize105",
		"inputs": []
	},
	{
		"type": "function",
		"stateMutability": "view",
		"outputs": [
			{
				"type": "uint256",
				"name": "",
				"internalType": "uint256"
			}
		],
		"name": "prize106",
		"inputs": []
	},
	{
		"type": "function",
		"stateMutability": "view",
		"outputs": [
			{
				"type": "uint256",
				"name": "",
				"internalType": "uint256"
			}
		],
		"name": "prize107",
		"inputs": []
	},
	{
		"type": "function",
		"stateMutability": "view",
		"outputs": [
			{
				"type": "uint256",
				"name": "",
				"internalType": "uint256"
			}
		],
		"name": "prize108",
		"inputs": []
	},
	{
		"type": "function",
		"stateMutability": "view",
		"outputs": [
			{
				"type": "uint256",
				"name": "",
				"internalType": "uint256"
			}
		],
		"name": "prize109",
		"inputs": []
	},
	{
		"type": "function",
		"stateMutability": "view",
		"outputs": [
			{
				"type": "uint256",
				"name": "",
				"internalType": "uint256"
			}
		],
		"name": "prizeBelow100",
		"inputs": []
	},
	{
		"type": "function",
		"stateMutability": "view",
		"outputs": [
			{
				"type": "uint256",
				"name": "",
				"internalType": "uint256"
			}
		],
		"name": "randomNum",
		"inputs": [
			{
				"type": "uint256",
				"name": "_mod",
				"internalType": "uint256"
			},
			{
				"type": "uint256",
				"name": "_seed",
				"internalType": "uint256"
			},
			{
				"type": "uint256",
				"name": "_salt",
				"internalType": "uint256"
			}
		]
	},
	{
		"type": "function",
		"stateMutability": "view",
		"outputs": [
			{
				"type": "uint256",
				"name": "drawNumber",
				"internalType": "uint256"
			},
			{
				"type": "address",
				"name": "walletAddress",
				"internalType": "address"
			},
			{
				"type": "uint256",
				"name": "prize",
				"internalType": "uint256"
			},
			{
				"type": "uint256",
				"name": "winningTicketNumber",
				"internalType": "uint256"
			},
			{
				"type": "uint256",
				"name": "lotteryIndex",
				"internalType": "uint256"
			}
		],
		"name": "records",
		"inputs": [
			{
				"type": "uint256",
				"name": "",
				"internalType": "uint256"
			}
		]
	},
	{
		"type": "function",
		"stateMutability": "nonpayable",
		"outputs": [],
		"name": "renounceOwnership",
		"inputs": []
	},
	{
		"type": "function",
		"stateMutability": "nonpayable",
		"outputs": [],
		"name": "setDrawCount",
		"inputs": [
			{
				"type": "uint256",
				"name": "_drawCount",
				"internalType": "uint256"
			}
		]
	},
	{
		"type": "function",
		"stateMutability": "nonpayable",
		"outputs": [],
		"name": "setLotteryIndex",
		"inputs": [
			{
				"type": "uint256",
				"name": "_lotteryIndex",
				"internalType": "uint256"
			}
		]
	},
	{
		"type": "function",
		"stateMutability": "nonpayable",
		"outputs": [],
		"name": "setMaximumTicketCount",
		"inputs": [
			{
				"type": "uint256",
				"name": "_maximumTicketCount",
				"internalType": "uint256"
			}
		]
	},
	{
		"type": "function",
		"stateMutability": "nonpayable",
		"outputs": [],
		"name": "setMinimumTicketCount",
		"inputs": [
			{
				"type": "uint256",
				"name": "_minimumTicketCount",
				"internalType": "uint256"
			}
		]
	},
	{
		"type": "function",
		"stateMutability": "nonpayable",
		"outputs": [],
		"name": "setPkgPrices",
		"inputs": [
			{
				"type": "uint256",
				"name": "_package0Price",
				"internalType": "uint256"
			},
			{
				"type": "uint256",
				"name": "_package1Price",
				"internalType": "uint256"
			},
			{
				"type": "uint256",
				"name": "_package2Price",
				"internalType": "uint256"
			}
		]
	},
	{
		"type": "function",
		"stateMutability": "nonpayable",
		"outputs": [],
		"name": "setPrizeAmount",
		"inputs": [
			{
				"type": "uint256",
				"name": "_prizeBelow100",
				"internalType": "uint256"
			},
			{
				"type": "uint256",
				"name": "_prize100",
				"internalType": "uint256"
			},
			{
				"type": "uint256",
				"name": "_prize101",
				"internalType": "uint256"
			},
			{
				"type": "uint256",
				"name": "_prize102",
				"internalType": "uint256"
			},
			{
				"type": "uint256",
				"name": "_prize103",
				"internalType": "uint256"
			},
			{
				"type": "uint256",
				"name": "_prize104",
				"internalType": "uint256"
			},
			{
				"type": "uint256",
				"name": "_prize105",
				"internalType": "uint256"
			},
			{
				"type": "uint256",
				"name": "_prize106",
				"internalType": "uint256"
			},
			{
				"type": "uint256",
				"name": "_prize107",
				"internalType": "uint256"
			},
			{
				"type": "uint256",
				"name": "_prize108",
				"internalType": "uint256"
			},
			{
				"type": "uint256",
				"name": "_prize109",
				"internalType": "uint256"
			}
		]
	},
	{
		"type": "function",
		"stateMutability": "nonpayable",
		"outputs": [],
		"name": "setTax",
		"inputs": [
			{
				"type": "uint256",
				"name": "_burnWalletTax",
				"internalType": "uint256"
			},
			{
				"type": "uint256",
				"name": "_buyBackTax",
				"internalType": "uint256"
			},
			{
				"type": "uint256",
				"name": "_nftHoldersReflectionTax",
				"internalType": "uint256"
			},
			{
				"type": "uint256",
				"name": "_platformTax",
				"internalType": "uint256"
			}
		]
	},
	{
		"type": "function",
		"stateMutability": "nonpayable",
		"outputs": [],
		"name": "setTicketToken",
		"inputs": [
			{
				"type": "address",
				"name": "_ticketToken",
				"internalType": "address"
			}
		]
	},
	{
		"type": "function",
		"stateMutability": "nonpayable",
		"outputs": [],
		"name": "setWallets",
		"inputs": [
			{
				"type": "address",
				"name": "_burnWallet",
				"internalType": "address"
			},
			{
				"type": "address",
				"name": "_buyBack",
				"internalType": "address"
			},
			{
				"type": "address",
				"name": "_nftHoldersReflection",
				"internalType": "address"
			},
			{
				"type": "address",
				"name": "_platform",
				"internalType": "address"
			}
		]
	},
	{
		"type": "function",
		"stateMutability": "nonpayable",
		"outputs": [],
		"name": "setWinnerWallet",
		"inputs": [
			{
				"type": "uint256",
				"name": "_winnerWallet",
				"internalType": "uint256"
			}
		]
	},
	{
		"type": "function",
		"stateMutability": "view",
		"outputs": [
			{
				"type": "uint256",
				"name": "",
				"internalType": "uint256"
			}
		],
		"name": "ticketCount",
		"inputs": []
	},
	{
		"type": "function",
		"stateMutability": "view",
		"outputs": [
			{
				"type": "address",
				"name": "",
				"internalType": "address"
			}
		],
		"name": "ticketHolders",
		"inputs": [
			{
				"type": "uint256",
				"name": "",
				"internalType": "uint256"
			}
		]
	},
	{
		"type": "function",
		"stateMutability": "view",
		"outputs": [
			{
				"type": "address",
				"name": "",
				"internalType": "contract IERC20"
			}
		],
		"name": "ticketToken",
		"inputs": []
	},
	{
		"type": "function",
		"stateMutability": "nonpayable",
		"outputs": [],
		"name": "tokenWithdrawal",
		"inputs": []
	},
	{
		"type": "function",
		"stateMutability": "view",
		"outputs": [
			{
				"type": "uint256",
				"name": "",
				"internalType": "uint256"
			}
		],
		"name": "totalPrize",
		"inputs": []
	},
	{
		"type": "function",
		"stateMutability": "nonpayable",
		"outputs": [],
		"name": "transferOwnership",
		"inputs": [
			{
				"type": "address",
				"name": "newOwner",
				"internalType": "address"
			}
		]
	},
	{
		"type": "function",
		"stateMutability": "view",
		"outputs": [
			{
				"type": "address",
				"name": "",
				"internalType": "address"
			}
		],
		"name": "winner",
		"inputs": []
	},
	{
		"type": "function",
		"stateMutability": "view",
		"outputs": [
			{
				"type": "uint256",
				"name": "",
				"internalType": "uint256"
			}
		],
		"name": "winnerWallet",
		"inputs": []
	},
	{
		"type": "function",
		"stateMutability": "view",
		"outputs": [
			{
				"type": "address",
				"name": "",
				"internalType": "address"
			}
		],
		"name": "winners",
		"inputs": [
			{
				"type": "uint256",
				"name": "",
				"internalType": "uint256"
			}
		]
	},
	{
		"type": "function",
		"stateMutability": "view",
		"outputs": [
			{
				"type": "uint256",
				"name": "",
				"internalType": "uint256"
			}
		],
		"name": "winningTicketNumbers",
		"inputs": [
			{
				"type": "uint256",
				"name": "",
				"internalType": "uint256"
			}
		]
	},
	{
		"type": "function",
		"stateMutability": "nonpayable",
		"outputs": [],
		"name": "withdraw",
		"inputs": []
	}
];

const lotteryaddress = "0x9B660cD1e94F67268e96844Cae397bd2860c2cBa";

//const lotteryaddress = "0x41AA5BB6415865BbE69F799E049AD89f47067c85"; 

//const lotteryaddress = "0x76d37aE0ff14172A2c985F734870F66E605128b7";  

//const lotteryaddress = "0x0b83688528D10E4a3aF3f85349158132b9665F27";

//......................NEW CONTRACT................................//

//.....................OLD CONTRACT................................//

/*const lotteryABI = [
	{
		"type": "constructor",
		"stateMutability": "nonpayable",
		"inputs": []
	},
	{
		"type": "event",
		"name": "OwnershipTransferred",
		"inputs": [
			{
				"type": "address",
				"name": "previousOwner",
				"internalType": "address",
				"indexed": true
			},
			{
				"type": "address",
				"name": "newOwner",
				"internalType": "address",
				"indexed": true
			}
		],
		"anonymous": false
	},
	{
		"type": "function",
		"stateMutability": "nonpayable",
		"outputs": [],
		"name": "autoDrawLottery",
		"inputs": []
	},
	{
		"type": "function",
		"stateMutability": "view",
		"outputs": [
			{
				"type": "address",
				"name": "",
				"internalType": "address"
			}
		],
		"name": "burnWallet",
		"inputs": []
	},
	{
		"type": "function",
		"stateMutability": "view",
		"outputs": [
			{
				"type": "uint256",
				"name": "",
				"internalType": "uint256"
			}
		],
		"name": "burnWalletTax",
		"inputs": []
	},
	{
		"type": "function",
		"stateMutability": "view",
		"outputs": [
			{
				"type": "address",
				"name": "",
				"internalType": "address"
			}
		],
		"name": "buyBack",
		"inputs": []
	},
	{
		"type": "function",
		"stateMutability": "view",
		"outputs": [
			{
				"type": "uint256",
				"name": "",
				"internalType": "uint256"
			}
		],
		"name": "buyBackTax",
		"inputs": []
	},
	{
		"type": "function",
		"stateMutability": "payable",
		"outputs": [],
		"name": "deposit",
		"inputs": []
	},
	{
		"type": "function",
		"stateMutability": "view",
		"outputs": [
			{
				"type": "uint256",
				"name": "",
				"internalType": "uint256"
			}
		],
		"name": "depositedAmounts",
		"inputs": [
			{
				"type": "uint256",
				"name": "",
				"internalType": "uint256"
			}
		]
	},
	{
		"type": "function",
		"stateMutability": "nonpayable",
		"outputs": [],
		"name": "distributePrize",
		"inputs": []
	},
	{
		"type": "function",
		"stateMutability": "view",
		"outputs": [
			{
				"type": "uint256",
				"name": "",
				"internalType": "uint256"
			}
		],
		"name": "drawCount",
		"inputs": []
	},
	{
		"type": "function",
		"stateMutability": "nonpayable",
		"outputs": [],
		"name": "drawLottery",
		"inputs": []
	},
	{
		"type": "function",
		"stateMutability": "view",
		"outputs": [
			{
				"type": "uint256",
				"name": "",
				"internalType": "uint256"
			}
		],
		"name": "lotteryIndex",
		"inputs": []
	},
	{
		"type": "function",
		"stateMutability": "view",
		"outputs": [
			{
				"type": "uint256",
				"name": "",
				"internalType": "uint256"
			}
		],
		"name": "maximumTicketCount",
		"inputs": []
	},
	{
		"type": "function",
		"stateMutability": "view",
		"outputs": [
			{
				"type": "uint256",
				"name": "",
				"internalType": "uint256"
			}
		],
		"name": "minimumTicketCount",
		"inputs": []
	},
	{
		"type": "function",
		"stateMutability": "view",
		"outputs": [
			{
				"type": "uint256",
				"name": "",
				"internalType": "uint256"
			}
		],
		"name": "myTicketCount",
		"inputs": [
			{
				"type": "address",
				"name": "",
				"internalType": "address"
			},
			{
				"type": "uint256",
				"name": "",
				"internalType": "uint256"
			}
		]
	},
	{
		"type": "function",
		"stateMutability": "view",
		"outputs": [
			{
				"type": "uint256",
				"name": "",
				"internalType": "uint256"
			}
		],
		"name": "myTickets",
		"inputs": [
			{
				"type": "address",
				"name": "",
				"internalType": "address"
			},
			{
				"type": "uint256",
				"name": "",
				"internalType": "uint256"
			},
			{
				"type": "uint256",
				"name": "",
				"internalType": "uint256"
			}
		]
	},
	{
		"type": "function",
		"stateMutability": "view",
		"outputs": [
			{
				"type": "address",
				"name": "",
				"internalType": "address"
			}
		],
		"name": "nftHoldersReflection",
		"inputs": []
	},
	{
		"type": "function",
		"stateMutability": "view",
		"outputs": [
			{
				"type": "uint256",
				"name": "",
				"internalType": "uint256"
			}
		],
		"name": "nftHoldersReflectionTax",
		"inputs": []
	},
	{
		"type": "function",
		"stateMutability": "view",
		"outputs": [
			{
				"type": "address",
				"name": "",
				"internalType": "address"
			}
		],
		"name": "owner",
		"inputs": []
	},
	{
		"type": "function",
		"stateMutability": "nonpayable",
		"outputs": [],
		"name": "pkg0",
		"inputs": []
	},
	{
		"type": "function",
		"stateMutability": "nonpayable",
		"outputs": [],
		"name": "pkg1",
		"inputs": []
	},
	{
		"type": "function",
		"stateMutability": "nonpayable",
		"outputs": [],
		"name": "pkg2",
		"inputs": []
	},
	{
		"type": "function",
		"stateMutability": "view",
		"outputs": [
			{
				"type": "address",
				"name": "",
				"internalType": "address"
			}
		],
		"name": "platform",
		"inputs": []
	},
	{
		"type": "function",
		"stateMutability": "view",
		"outputs": [
			{
				"type": "uint256",
				"name": "",
				"internalType": "uint256"
			}
		],
		"name": "platformTax",
		"inputs": []
	},
	{
		"type": "function",
		"stateMutability": "view",
		"outputs": [
			{
				"type": "uint256",
				"name": "",
				"internalType": "uint256"
			}
		],
		"name": "prize100",
		"inputs": []
	},
	{
		"type": "function",
		"stateMutability": "view",
		"outputs": [
			{
				"type": "uint256",
				"name": "",
				"internalType": "uint256"
			}
		],
		"name": "prize101",
		"inputs": []
	},
	{
		"type": "function",
		"stateMutability": "view",
		"outputs": [
			{
				"type": "uint256",
				"name": "",
				"internalType": "uint256"
			}
		],
		"name": "prize102",
		"inputs": []
	},
	{
		"type": "function",
		"stateMutability": "view",
		"outputs": [
			{
				"type": "uint256",
				"name": "",
				"internalType": "uint256"
			}
		],
		"name": "prize103",
		"inputs": []
	},
	{
		"type": "function",
		"stateMutability": "view",
		"outputs": [
			{
				"type": "uint256",
				"name": "",
				"internalType": "uint256"
			}
		],
		"name": "prize104",
		"inputs": []
	},
	{
		"type": "function",
		"stateMutability": "view",
		"outputs": [
			{
				"type": "uint256",
				"name": "",
				"internalType": "uint256"
			}
		],
		"name": "prize105",
		"inputs": []
	},
	{
		"type": "function",
		"stateMutability": "view",
		"outputs": [
			{
				"type": "uint256",
				"name": "",
				"internalType": "uint256"
			}
		],
		"name": "prize106",
		"inputs": []
	},
	{
		"type": "function",
		"stateMutability": "view",
		"outputs": [
			{
				"type": "uint256",
				"name": "",
				"internalType": "uint256"
			}
		],
		"name": "prize107",
		"inputs": []
	},
	{
		"type": "function",
		"stateMutability": "view",
		"outputs": [
			{
				"type": "uint256",
				"name": "",
				"internalType": "uint256"
			}
		],
		"name": "prize108",
		"inputs": []
	},
	{
		"type": "function",
		"stateMutability": "view",
		"outputs": [
			{
				"type": "uint256",
				"name": "",
				"internalType": "uint256"
			}
		],
		"name": "prize109",
		"inputs": []
	},
	{
		"type": "function",
		"stateMutability": "view",
		"outputs": [
			{
				"type": "uint256",
				"name": "",
				"internalType": "uint256"
			}
		],
		"name": "prizeBelow100",
		"inputs": []
	},
	{
		"type": "function",
		"stateMutability": "view",
		"outputs": [
			{
				"type": "uint256",
				"name": "",
				"internalType": "uint256"
			}
		],
		"name": "randomNum",
		"inputs": [
			{
				"type": "uint256",
				"name": "_mod",
				"internalType": "uint256"
			},
			{
				"type": "uint256",
				"name": "_seed",
				"internalType": "uint256"
			},
			{
				"type": "uint256",
				"name": "_salt",
				"internalType": "uint256"
			}
		]
	},
	{
		"type": "function",
		"stateMutability": "view",
		"outputs": [
			{
				"type": "uint256",
				"name": "drawNumber",
				"internalType": "uint256"
			},
			{
				"type": "address",
				"name": "walletAddress",
				"internalType": "address"
			},
			{
				"type": "uint256",
				"name": "prize",
				"internalType": "uint256"
			},
			{
				"type": "uint256",
				"name": "winningTicketNumber",
				"internalType": "uint256"
			},
			{
				"type": "uint256",
				"name": "lotteryIndex",
				"internalType": "uint256"
			}
		],
		"name": "records",
		"inputs": [
			{
				"type": "uint256",
				"name": "",
				"internalType": "uint256"
			}
		]
	},
	{
		"type": "function",
		"stateMutability": "nonpayable",
		"outputs": [],
		"name": "renounceOwnership",
		"inputs": []
	},
	{
		"type": "function",
		"stateMutability": "nonpayable",
		"outputs": [],
		"name": "setDrawCount",
		"inputs": [
			{
				"type": "uint256",
				"name": "_drawCount",
				"internalType": "uint256"
			}
		]
	},
	{
		"type": "function",
		"stateMutability": "nonpayable",
		"outputs": [],
		"name": "setLotteryIndex",
		"inputs": [
			{
				"type": "uint256",
				"name": "_lotteryIndex",
				"internalType": "uint256"
			}
		]
	},
	{
		"type": "function",
		"stateMutability": "nonpayable",
		"outputs": [],
		"name": "setMaximumTicketCount",
		"inputs": [
			{
				"type": "uint256",
				"name": "_maximumTicketCount",
				"internalType": "uint256"
			}
		]
	},
	{
		"type": "function",
		"stateMutability": "nonpayable",
		"outputs": [],
		"name": "setMinimumTicketCount",
		"inputs": [
			{
				"type": "uint256",
				"name": "_minimumTicketCount",
				"internalType": "uint256"
			}
		]
	},
	{
		"type": "function",
		"stateMutability": "nonpayable",
		"outputs": [],
		"name": "setPkgPrices",
		"inputs": [
			{
				"type": "uint256",
				"name": "_package0Price",
				"internalType": "uint256"
			},
			{
				"type": "uint256",
				"name": "_package1Price",
				"internalType": "uint256"
			},
			{
				"type": "uint256",
				"name": "_package2Price",
				"internalType": "uint256"
			}
		]
	},
	{
		"type": "function",
		"stateMutability": "nonpayable",
		"outputs": [],
		"name": "setPrizeAmount",
		"inputs": [
			{
				"type": "uint256",
				"name": "_prizeBelow100",
				"internalType": "uint256"
			},
			{
				"type": "uint256",
				"name": "_prize100",
				"internalType": "uint256"
			},
			{
				"type": "uint256",
				"name": "_prize101",
				"internalType": "uint256"
			},
			{
				"type": "uint256",
				"name": "_prize102",
				"internalType": "uint256"
			},
			{
				"type": "uint256",
				"name": "_prize103",
				"internalType": "uint256"
			},
			{
				"type": "uint256",
				"name": "_prize104",
				"internalType": "uint256"
			},
			{
				"type": "uint256",
				"name": "_prize105",
				"internalType": "uint256"
			},
			{
				"type": "uint256",
				"name": "_prize106",
				"internalType": "uint256"
			},
			{
				"type": "uint256",
				"name": "_prize107",
				"internalType": "uint256"
			},
			{
				"type": "uint256",
				"name": "_prize108",
				"internalType": "uint256"
			},
			{
				"type": "uint256",
				"name": "_prize109",
				"internalType": "uint256"
			}
		]
	},
	{
		"type": "function",
		"stateMutability": "nonpayable",
		"outputs": [],
		"name": "setTax",
		"inputs": [
			{
				"type": "uint256",
				"name": "_burnWalletTax",
				"internalType": "uint256"
			},
			{
				"type": "uint256",
				"name": "_buyBackTax",
				"internalType": "uint256"
			},
			{
				"type": "uint256",
				"name": "_nftHoldersReflectionTax",
				"internalType": "uint256"
			},
			{
				"type": "uint256",
				"name": "_platformTax",
				"internalType": "uint256"
			}
		]
	},
	{
		"type": "function",
		"stateMutability": "nonpayable",
		"outputs": [],
		"name": "setTicketToken",
		"inputs": [
			{
				"type": "address",
				"name": "_ticketToken",
				"internalType": "address"
			}
		]
	},
	{
		"type": "function",
		"stateMutability": "nonpayable",
		"outputs": [],
		"name": "setTokenContract",
		"inputs": [
			{
				"type": "address",
				"name": "_tokenContract",
				"internalType": "address"
			}
		]
	},
	{
		"type": "function",
		"stateMutability": "nonpayable",
		"outputs": [],
		"name": "setWallets",
		"inputs": [
			{
				"type": "address",
				"name": "_burnWallet",
				"internalType": "address"
			},
			{
				"type": "address",
				"name": "_buyBack",
				"internalType": "address"
			},
			{
				"type": "address",
				"name": "_nftHoldersReflection",
				"internalType": "address"
			},
			{
				"type": "address",
				"name": "_platform",
				"internalType": "address"
			}
		]
	},
	{
		"type": "function",
		"stateMutability": "view",
		"outputs": [
			{
				"type": "uint256",
				"name": "",
				"internalType": "uint256"
			}
		],
		"name": "ticketCount",
		"inputs": []
	},
	{
		"type": "function",
		"stateMutability": "view",
		"outputs": [
			{
				"type": "address",
				"name": "",
				"internalType": "address"
			}
		],
		"name": "ticketHolders",
		"inputs": [
			{
				"type": "uint256",
				"name": "",
				"internalType": "uint256"
			}
		]
	},
	{
		"type": "function",
		"stateMutability": "view",
		"outputs": [
			{
				"type": "address",
				"name": "",
				"internalType": "contract IERC20"
			}
		],
		"name": "ticketToken",
		"inputs": []
	},
	{
		"type": "function",
		"stateMutability": "view",
		"outputs": [
			{
				"type": "address",
				"name": "",
				"internalType": "contract IERC20"
			}
		],
		"name": "token",
		"inputs": []
	},
	{
		"type": "function",
		"stateMutability": "nonpayable",
		"outputs": [],
		"name": "tokenWithdrawal",
		"inputs": []
	},
	{
		"type": "function",
		"stateMutability": "view",
		"outputs": [
			{
				"type": "uint256",
				"name": "",
				"internalType": "uint256"
			}
		],
		"name": "totalPrize",
		"inputs": []
	},
	{
		"type": "function",
		"stateMutability": "nonpayable",
		"outputs": [],
		"name": "transferOwnership",
		"inputs": [
			{
				"type": "address",
				"name": "newOwner",
				"internalType": "address"
			}
		]
	},
	{
		"type": "function",
		"stateMutability": "view",
		"outputs": [
			{
				"type": "address",
				"name": "",
				"internalType": "address"
			}
		],
		"name": "winner",
		"inputs": []
	},
	{
		"type": "function",
		"stateMutability": "view",
		"outputs": [
			{
				"type": "address",
				"name": "",
				"internalType": "address"
			}
		],
		"name": "winners",
		"inputs": [
			{
				"type": "uint256",
				"name": "",
				"internalType": "uint256"
			}
		]
	},
	{
		"type": "function",
		"stateMutability": "view",
		"outputs": [
			{
				"type": "uint256",
				"name": "",
				"internalType": "uint256"
			}
		],
		"name": "winningTicketNumbers",
		"inputs": [
			{
				"type": "uint256",
				"name": "",
				"internalType": "uint256"
			}
		]
	},
	{
		"type": "function",
		"stateMutability": "nonpayable",
		"outputs": [],
		"name": "withdraw",
		"inputs": []
	}
];

const lotteryaddress = "0x51b05CCD5aA2E87aA731849eae54649Fa4ad54D6";*/

//.....................OLD CONTRACT................................//


/*const lotteryABI = [
	{
		"inputs": [],
		"name": "deposit",
		"outputs": [],
		"stateMutability": "payable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "distributePrize",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "drawLottery",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "pkg1",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"stateMutability": "nonpayable",
		"type": "constructor"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "previousOwner",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "address",
				"name": "newOwner",
				"type": "address"
			}
		],
		"name": "OwnershipTransferred",
		"type": "event"
	},
	{
		"inputs": [],
		"name": "pkg0",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "pkg2",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "renounceOwnership",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_drawCount",
				"type": "uint256"
			}
		],
		"name": "setDrawCount",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_lotteryIndex",
				"type": "uint256"
			}
		],
		"name": "setLotteryIndex",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_maximumTicketCount",
				"type": "uint256"
			}
		],
		"name": "setMaximumTicketCount",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_minimumTicketCount",
				"type": "uint256"
			}
		],
		"name": "setMinimumTicketCount",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_package0Price",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "_package1Price",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "_package2Price",
				"type": "uint256"
			}
		],
		"name": "setPkgPrices",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_prizeBelow100",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "_prize100",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "_prize101",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "_prize102",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "_prize103",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "_prize104",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "_prize105",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "_prize106",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "_prize107",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "_prize108",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "_prize109",
				"type": "uint256"
			}
		],
		"name": "setPrizeAmount",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_burnWalletTax",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "_buyBackTax",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "_nftHoldersReflectionTax",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "_platformTax",
				"type": "uint256"
			}
		],
		"name": "setTax",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "_ticketToken",
				"type": "address"
			}
		],
		"name": "setTicketToken",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "_burnWallet",
				"type": "address"
			},
			{
				"internalType": "address",
				"name": "_buyBack",
				"type": "address"
			},
			{
				"internalType": "address",
				"name": "_nftHoldersReflection",
				"type": "address"
			},
			{
				"internalType": "address",
				"name": "_platform",
				"type": "address"
			}
		],
		"name": "setWallets",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "tokenWithdrawal",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "newOwner",
				"type": "address"
			}
		],
		"name": "transferOwnership",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "withdraw",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "burnWallet",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "burnWalletTax",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "buyBack",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "buyBackTax",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"name": "depositedAmounts",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "drawCount",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "getLotteryIndex",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "index",
				"type": "uint256"
			}
		],
		"name": "getRecord",
		"outputs": [
			{
				"components": [
					{
						"internalType": "uint256",
						"name": "drawNumber",
						"type": "uint256"
					},
					{
						"internalType": "address",
						"name": "walletAddress",
						"type": "address"
					},
					{
						"internalType": "uint256",
						"name": "prize",
						"type": "uint256"
					},
					{
						"internalType": "uint256",
						"name": "winningTicketNumber",
						"type": "uint256"
					},
					{
						"internalType": "uint256",
						"name": "lotteryIndex",
						"type": "uint256"
					}
				],
				"internalType": "struct POMLotteryDraw.Records",
				"name": "",
				"type": "tuple"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "getRecords",
		"outputs": [
			{
				"components": [
					{
						"internalType": "uint256",
						"name": "drawNumber",
						"type": "uint256"
					},
					{
						"internalType": "address",
						"name": "walletAddress",
						"type": "address"
					},
					{
						"internalType": "uint256",
						"name": "prize",
						"type": "uint256"
					},
					{
						"internalType": "uint256",
						"name": "winningTicketNumber",
						"type": "uint256"
					},
					{
						"internalType": "uint256",
						"name": "lotteryIndex",
						"type": "uint256"
					}
				],
				"internalType": "struct POMLotteryDraw.Records[]",
				"name": "",
				"type": "tuple[]"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "getTicketCount",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "lotteryIndex",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "maximumTicketCount",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "minimumTicketCount",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"name": "myTicketCount",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"name": "myTickets",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "nftHoldersReflection",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "nftHoldersReflectionTax",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "owner",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "platform",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "platformTax",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "prize100",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "prize101",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "prize102",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "prize103",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "prize104",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "prize105",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "prize106",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "prize107",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "prize108",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "prize109",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "prizeBelow100",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_mod",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "_seed",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "_salt",
				"type": "uint256"
			}
		],
		"name": "randomNum",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"name": "records",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "drawNumber",
				"type": "uint256"
			},
			{
				"internalType": "address",
				"name": "walletAddress",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "prize",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "winningTicketNumber",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "lotteryIndex",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "ticketCount",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"name": "ticketHolders",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "ticketToken",
		"outputs": [
			{
				"internalType": "contract IERC20",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "totalPrize",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "winner",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"name": "winners",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"name": "winningTicketNumbers",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	}
];

const lotteryaddress = "0xf7C84295b8db88aA1FC8b49761c734BF39F1b385";*/

const tokenABI = [
	{
		"type": "constructor",
		"stateMutability": "nonpayable",
		"inputs": []
	},
	{
		"type": "event",
		"name": "Approval",
		"inputs": [
			{
				"type": "address",
				"name": "owner",
				"internalType": "address",
				"indexed": true
			},
			{
				"type": "address",
				"name": "spender",
				"internalType": "address",
				"indexed": true
			},
			{
				"type": "uint256",
				"name": "value",
				"internalType": "uint256",
				"indexed": false
			}
		],
		"anonymous": false
	},
	{
		"type": "event",
		"name": "AutoNukeLP",
		"inputs": [],
		"anonymous": false
	},
	{
		"type": "event",
		"name": "BoughtEarly",
		"inputs": [
			{
				"type": "address",
				"name": "sniper",
				"internalType": "address",
				"indexed": true
			}
		],
		"anonymous": false
	},
	{
		"type": "event",
		"name": "ExcludeFromFees",
		"inputs": [
			{
				"type": "address",
				"name": "account",
				"internalType": "address",
				"indexed": true
			},
			{
				"type": "bool",
				"name": "isExcluded",
				"internalType": "bool",
				"indexed": false
			}
		],
		"anonymous": false
	},
	{
		"type": "event",
		"name": "ManualNukeLP",
		"inputs": [],
		"anonymous": false
	},
	{
		"type": "event",
		"name": "OwnershipTransferred",
		"inputs": [
			{
				"type": "address",
				"name": "previousOwner",
				"internalType": "address",
				"indexed": true
			},
			{
				"type": "address",
				"name": "newOwner",
				"internalType": "address",
				"indexed": true
			}
		],
		"anonymous": false
	},
	{
		"type": "event",
		"name": "SetAutomatedMarketMakerPair",
		"inputs": [
			{
				"type": "address",
				"name": "pair",
				"internalType": "address",
				"indexed": true
			},
			{
				"type": "bool",
				"name": "value",
				"internalType": "bool",
				"indexed": true
			}
		],
		"anonymous": false
	},
	{
		"type": "event",
		"name": "SwapAndLiquify",
		"inputs": [
			{
				"type": "uint256",
				"name": "tokensSwapped",
				"internalType": "uint256",
				"indexed": false
			},
			{
				"type": "uint256",
				"name": "ethReceived",
				"internalType": "uint256",
				"indexed": false
			},
			{
				"type": "uint256",
				"name": "tokensIntoLiquidity",
				"internalType": "uint256",
				"indexed": false
			}
		],
		"anonymous": false
	},
	{
		"type": "event",
		"name": "Transfer",
		"inputs": [
			{
				"type": "address",
				"name": "from",
				"internalType": "address",
				"indexed": true
			},
			{
				"type": "address",
				"name": "to",
				"internalType": "address",
				"indexed": true
			},
			{
				"type": "uint256",
				"name": "value",
				"internalType": "uint256",
				"indexed": false
			}
		],
		"anonymous": false
	},
	{
		"type": "event",
		"name": "UpdateUniswapV2Router",
		"inputs": [
			{
				"type": "address",
				"name": "newAddress",
				"internalType": "address",
				"indexed": true
			},
			{
				"type": "address",
				"name": "oldAddress",
				"internalType": "address",
				"indexed": true
			}
		],
		"anonymous": false
	},
	{
		"type": "event",
		"name": "devWalletUpdated",
		"inputs": [
			{
				"type": "address",
				"name": "newWallet",
				"internalType": "address",
				"indexed": true
			},
			{
				"type": "address",
				"name": "oldWallet",
				"internalType": "address",
				"indexed": true
			}
		],
		"anonymous": false
	},
	{
		"type": "event",
		"name": "marketingWalletUpdated",
		"inputs": [
			{
				"type": "address",
				"name": "newWallet",
				"internalType": "address",
				"indexed": true
			},
			{
				"type": "address",
				"name": "oldWallet",
				"internalType": "address",
				"indexed": true
			}
		],
		"anonymous": false
	},
	{
		"type": "function",
		"stateMutability": "view",
		"outputs": [
			{
				"type": "bool",
				"name": "",
				"internalType": "bool"
			}
		],
		"name": "_isExcludedMaxTransactionAmount",
		"inputs": [
			{
				"type": "address",
				"name": "",
				"internalType": "address"
			}
		]
	},
	{
		"type": "function",
		"stateMutability": "view",
		"outputs": [
			{
				"type": "uint256",
				"name": "",
				"internalType": "uint256"
			}
		],
		"name": "allowance",
		"inputs": [
			{
				"type": "address",
				"name": "owner",
				"internalType": "address"
			},
			{
				"type": "address",
				"name": "spender",
				"internalType": "address"
			}
		]
	},
	{
		"type": "function",
		"stateMutability": "nonpayable",
		"outputs": [
			{
				"type": "bool",
				"name": "",
				"internalType": "bool"
			}
		],
		"name": "approve",
		"inputs": [
			{
				"type": "address",
				"name": "spender",
				"internalType": "address"
			},
			{
				"type": "uint256",
				"name": "amount",
				"internalType": "uint256"
			}
		]
	},
	{
		"type": "function",
		"stateMutability": "view",
		"outputs": [
			{
				"type": "bool",
				"name": "",
				"internalType": "bool"
			}
		],
		"name": "automatedMarketMakerPairs",
		"inputs": [
			{
				"type": "address",
				"name": "",
				"internalType": "address"
			}
		]
	},
	{
		"type": "function",
		"stateMutability": "view",
		"outputs": [
			{
				"type": "uint256",
				"name": "",
				"internalType": "uint256"
			}
		],
		"name": "balanceOf",
		"inputs": [
			{
				"type": "address",
				"name": "account",
				"internalType": "address"
			}
		]
	},
	{
		"type": "function",
		"stateMutability": "nonpayable",
		"outputs": [],
		"name": "blacklistAccount",
		"inputs": [
			{
				"type": "address",
				"name": "account",
				"internalType": "address"
			},
			{
				"type": "bool",
				"name": "isBlacklisted",
				"internalType": "bool"
			}
		]
	},
	{
		"type": "function",
		"stateMutability": "view",
		"outputs": [
			{
				"type": "uint256",
				"name": "",
				"internalType": "uint256"
			}
		],
		"name": "buyDevFee",
		"inputs": []
	},
	{
		"type": "function",
		"stateMutability": "view",
		"outputs": [
			{
				"type": "uint256",
				"name": "",
				"internalType": "uint256"
			}
		],
		"name": "buyLiquidityFee",
		"inputs": []
	},
	{
		"type": "function",
		"stateMutability": "view",
		"outputs": [
			{
				"type": "uint256",
				"name": "",
				"internalType": "uint256"
			}
		],
		"name": "buyMarketingFee",
		"inputs": []
	},
	{
		"type": "function",
		"stateMutability": "view",
		"outputs": [
			{
				"type": "uint256",
				"name": "",
				"internalType": "uint256"
			}
		],
		"name": "buyTotalFees",
		"inputs": []
	},
	{
		"type": "function",
		"stateMutability": "view",
		"outputs": [
			{
				"type": "address",
				"name": "",
				"internalType": "address"
			}
		],
		"name": "deadAddress",
		"inputs": []
	},
	{
		"type": "function",
		"stateMutability": "view",
		"outputs": [
			{
				"type": "uint8",
				"name": "",
				"internalType": "uint8"
			}
		],
		"name": "decimals",
		"inputs": []
	},
	{
		"type": "function",
		"stateMutability": "nonpayable",
		"outputs": [
			{
				"type": "bool",
				"name": "",
				"internalType": "bool"
			}
		],
		"name": "decreaseAllowance",
		"inputs": [
			{
				"type": "address",
				"name": "spender",
				"internalType": "address"
			},
			{
				"type": "uint256",
				"name": "subtractedValue",
				"internalType": "uint256"
			}
		]
	},
	{
		"type": "function",
		"stateMutability": "view",
		"outputs": [
			{
				"type": "address",
				"name": "",
				"internalType": "address"
			}
		],
		"name": "devWallet",
		"inputs": []
	},
	{
		"type": "function",
		"stateMutability": "nonpayable",
		"outputs": [
			{
				"type": "bool",
				"name": "",
				"internalType": "bool"
			}
		],
		"name": "disableTransferDelay",
		"inputs": []
	},
	{
		"type": "function",
		"stateMutability": "view",
		"outputs": [
			{
				"type": "uint256",
				"name": "",
				"internalType": "uint256"
			}
		],
		"name": "earlySellLiquidityFee",
		"inputs": []
	},
	{
		"type": "function",
		"stateMutability": "view",
		"outputs": [
			{
				"type": "uint256",
				"name": "",
				"internalType": "uint256"
			}
		],
		"name": "earlySellMarketingFee",
		"inputs": []
	},
	{
		"type": "function",
		"stateMutability": "view",
		"outputs": [
			{
				"type": "bool",
				"name": "",
				"internalType": "bool"
			}
		],
		"name": "enableEarlySellTax",
		"inputs": []
	},
	{
		"type": "function",
		"stateMutability": "nonpayable",
		"outputs": [],
		"name": "enableTrading",
		"inputs": []
	},
	{
		"type": "function",
		"stateMutability": "nonpayable",
		"outputs": [],
		"name": "excludeFromFees",
		"inputs": [
			{
				"type": "address",
				"name": "account",
				"internalType": "address"
			},
			{
				"type": "bool",
				"name": "excluded",
				"internalType": "bool"
			}
		]
	},
	{
		"type": "function",
		"stateMutability": "nonpayable",
		"outputs": [],
		"name": "excludeFromMaxTransaction",
		"inputs": [
			{
				"type": "address",
				"name": "updAds",
				"internalType": "address"
			},
			{
				"type": "bool",
				"name": "isEx",
				"internalType": "bool"
			}
		]
	},
	{
		"type": "function",
		"stateMutability": "nonpayable",
		"outputs": [
			{
				"type": "bool",
				"name": "",
				"internalType": "bool"
			}
		],
		"name": "increaseAllowance",
		"inputs": [
			{
				"type": "address",
				"name": "spender",
				"internalType": "address"
			},
			{
				"type": "uint256",
				"name": "addedValue",
				"internalType": "uint256"
			}
		]
	},
	{
		"type": "function",
		"stateMutability": "view",
		"outputs": [
			{
				"type": "bool",
				"name": "",
				"internalType": "bool"
			}
		],
		"name": "isExcludedFromFees",
		"inputs": [
			{
				"type": "address",
				"name": "account",
				"internalType": "address"
			}
		]
	},
	{
		"type": "function",
		"stateMutability": "view",
		"outputs": [
			{
				"type": "uint256",
				"name": "",
				"internalType": "uint256"
			}
		],
		"name": "lastLpBurnTime",
		"inputs": []
	},
	{
		"type": "function",
		"stateMutability": "view",
		"outputs": [
			{
				"type": "uint256",
				"name": "",
				"internalType": "uint256"
			}
		],
		"name": "lastManualLpBurnTime",
		"inputs": []
	},
	{
		"type": "function",
		"stateMutability": "view",
		"outputs": [
			{
				"type": "bool",
				"name": "",
				"internalType": "bool"
			}
		],
		"name": "limitsInEffect",
		"inputs": []
	},
	{
		"type": "function",
		"stateMutability": "view",
		"outputs": [
			{
				"type": "bool",
				"name": "",
				"internalType": "bool"
			}
		],
		"name": "lpBurnEnabled",
		"inputs": []
	},
	{
		"type": "function",
		"stateMutability": "view",
		"outputs": [
			{
				"type": "uint256",
				"name": "",
				"internalType": "uint256"
			}
		],
		"name": "lpBurnFrequency",
		"inputs": []
	},
	{
		"type": "function",
		"stateMutability": "view",
		"outputs": [
			{
				"type": "uint256",
				"name": "",
				"internalType": "uint256"
			}
		],
		"name": "manualBurnFrequency",
		"inputs": []
	},
	{
		"type": "function",
		"stateMutability": "nonpayable",
		"outputs": [
			{
				"type": "bool",
				"name": "",
				"internalType": "bool"
			}
		],
		"name": "manualBurnLiquidityPairTokens",
		"inputs": [
			{
				"type": "uint256",
				"name": "percent",
				"internalType": "uint256"
			}
		]
	},
	{
		"type": "function",
		"stateMutability": "view",
		"outputs": [
			{
				"type": "address",
				"name": "",
				"internalType": "address"
			}
		],
		"name": "marketingWallet",
		"inputs": []
	},
	{
		"type": "function",
		"stateMutability": "view",
		"outputs": [
			{
				"type": "uint256",
				"name": "",
				"internalType": "uint256"
			}
		],
		"name": "maxTransactionAmount",
		"inputs": []
	},
	{
		"type": "function",
		"stateMutability": "view",
		"outputs": [
			{
				"type": "uint256",
				"name": "",
				"internalType": "uint256"
			}
		],
		"name": "maxWallet",
		"inputs": []
	},
	{
		"type": "function",
		"stateMutability": "view",
		"outputs": [
			{
				"type": "string",
				"name": "",
				"internalType": "string"
			}
		],
		"name": "name",
		"inputs": []
	},
	{
		"type": "function",
		"stateMutability": "view",
		"outputs": [
			{
				"type": "address",
				"name": "",
				"internalType": "address"
			}
		],
		"name": "owner",
		"inputs": []
	},
	{
		"type": "function",
		"stateMutability": "view",
		"outputs": [
			{
				"type": "uint256",
				"name": "",
				"internalType": "uint256"
			}
		],
		"name": "percentForLPBurn",
		"inputs": []
	},
	{
		"type": "function",
		"stateMutability": "nonpayable",
		"outputs": [
			{
				"type": "bool",
				"name": "",
				"internalType": "bool"
			}
		],
		"name": "removeLimits",
		"inputs": []
	},
	{
		"type": "function",
		"stateMutability": "nonpayable",
		"outputs": [],
		"name": "renounceOwnership",
		"inputs": []
	},
	{
		"type": "function",
		"stateMutability": "view",
		"outputs": [
			{
				"type": "uint256",
				"name": "",
				"internalType": "uint256"
			}
		],
		"name": "sellDevFee",
		"inputs": []
	},
	{
		"type": "function",
		"stateMutability": "view",
		"outputs": [
			{
				"type": "uint256",
				"name": "",
				"internalType": "uint256"
			}
		],
		"name": "sellLiquidityFee",
		"inputs": []
	},
	{
		"type": "function",
		"stateMutability": "view",
		"outputs": [
			{
				"type": "uint256",
				"name": "",
				"internalType": "uint256"
			}
		],
		"name": "sellMarketingFee",
		"inputs": []
	},
	{
		"type": "function",
		"stateMutability": "view",
		"outputs": [
			{
				"type": "uint256",
				"name": "",
				"internalType": "uint256"
			}
		],
		"name": "sellTotalFees",
		"inputs": []
	},
	{
		"type": "function",
		"stateMutability": "nonpayable",
		"outputs": [],
		"name": "setAutoLPBurnSettings",
		"inputs": [
			{
				"type": "uint256",
				"name": "_frequencyInSeconds",
				"internalType": "uint256"
			},
			{
				"type": "uint256",
				"name": "_percent",
				"internalType": "uint256"
			},
			{
				"type": "bool",
				"name": "_Enabled",
				"internalType": "bool"
			}
		]
	},
	{
		"type": "function",
		"stateMutability": "nonpayable",
		"outputs": [],
		"name": "setAutomatedMarketMakerPair",
		"inputs": [
			{
				"type": "address",
				"name": "pair",
				"internalType": "address"
			},
			{
				"type": "bool",
				"name": "value",
				"internalType": "bool"
			}
		]
	},
	{
		"type": "function",
		"stateMutability": "nonpayable",
		"outputs": [],
		"name": "setEarlySellTax",
		"inputs": [
			{
				"type": "bool",
				"name": "onoff",
				"internalType": "bool"
			}
		]
	},
	{
		"type": "function",
		"stateMutability": "view",
		"outputs": [
			{
				"type": "bool",
				"name": "",
				"internalType": "bool"
			}
		],
		"name": "swapEnabled",
		"inputs": []
	},
	{
		"type": "function",
		"stateMutability": "view",
		"outputs": [
			{
				"type": "uint256",
				"name": "",
				"internalType": "uint256"
			}
		],
		"name": "swapTokensAtAmount",
		"inputs": []
	},
	{
		"type": "function",
		"stateMutability": "view",
		"outputs": [
			{
				"type": "string",
				"name": "",
				"internalType": "string"
			}
		],
		"name": "symbol",
		"inputs": []
	},
	{
		"type": "function",
		"stateMutability": "view",
		"outputs": [
			{
				"type": "uint256",
				"name": "",
				"internalType": "uint256"
			}
		],
		"name": "tokensForDev",
		"inputs": []
	},
	{
		"type": "function",
		"stateMutability": "view",
		"outputs": [
			{
				"type": "uint256",
				"name": "",
				"internalType": "uint256"
			}
		],
		"name": "tokensForLiquidity",
		"inputs": []
	},
	{
		"type": "function",
		"stateMutability": "view",
		"outputs": [
			{
				"type": "uint256",
				"name": "",
				"internalType": "uint256"
			}
		],
		"name": "tokensForMarketing",
		"inputs": []
	},
	{
		"type": "function",
		"stateMutability": "view",
		"outputs": [
			{
				"type": "uint256",
				"name": "",
				"internalType": "uint256"
			}
		],
		"name": "totalSupply",
		"inputs": []
	},
	{
		"type": "function",
		"stateMutability": "view",
		"outputs": [
			{
				"type": "bool",
				"name": "",
				"internalType": "bool"
			}
		],
		"name": "tradingActive",
		"inputs": []
	},
	{
		"type": "function",
		"stateMutability": "nonpayable",
		"outputs": [
			{
				"type": "bool",
				"name": "",
				"internalType": "bool"
			}
		],
		"name": "transfer",
		"inputs": [
			{
				"type": "address",
				"name": "recipient",
				"internalType": "address"
			},
			{
				"type": "uint256",
				"name": "amount",
				"internalType": "uint256"
			}
		]
	},
	{
		"type": "function",
		"stateMutability": "view",
		"outputs": [
			{
				"type": "bool",
				"name": "",
				"internalType": "bool"
			}
		],
		"name": "transferDelayEnabled",
		"inputs": []
	},
	{
		"type": "function",
		"stateMutability": "nonpayable",
		"outputs": [
			{
				"type": "bool",
				"name": "",
				"internalType": "bool"
			}
		],
		"name": "transferFrom",
		"inputs": [
			{
				"type": "address",
				"name": "sender",
				"internalType": "address"
			},
			{
				"type": "address",
				"name": "recipient",
				"internalType": "address"
			},
			{
				"type": "uint256",
				"name": "amount",
				"internalType": "uint256"
			}
		]
	},
	{
		"type": "function",
		"stateMutability": "nonpayable",
		"outputs": [],
		"name": "transferOwnership",
		"inputs": [
			{
				"type": "address",
				"name": "newOwner",
				"internalType": "address"
			}
		]
	},
	{
		"type": "function",
		"stateMutability": "view",
		"outputs": [
			{
				"type": "address",
				"name": "",
				"internalType": "address"
			}
		],
		"name": "uniswapV2Pair",
		"inputs": []
	},
	{
		"type": "function",
		"stateMutability": "view",
		"outputs": [
			{
				"type": "address",
				"name": "",
				"internalType": "contract IUniswapV2Router02"
			}
		],
		"name": "uniswapV2Router",
		"inputs": []
	},
	{
		"type": "function",
		"stateMutability": "nonpayable",
		"outputs": [],
		"name": "updateBuyFees",
		"inputs": [
			{
				"type": "uint256",
				"name": "_marketingFee",
				"internalType": "uint256"
			},
			{
				"type": "uint256",
				"name": "_liquidityFee",
				"internalType": "uint256"
			},
			{
				"type": "uint256",
				"name": "_devFee",
				"internalType": "uint256"
			}
		]
	},
	{
		"type": "function",
		"stateMutability": "nonpayable",
		"outputs": [],
		"name": "updateDevWallet",
		"inputs": [
			{
				"type": "address",
				"name": "newWallet",
				"internalType": "address"
			}
		]
	},
	{
		"type": "function",
		"stateMutability": "nonpayable",
		"outputs": [],
		"name": "updateMarketingWallet",
		"inputs": [
			{
				"type": "address",
				"name": "newMarketingWallet",
				"internalType": "address"
			}
		]
	},
	{
		"type": "function",
		"stateMutability": "nonpayable",
		"outputs": [],
		"name": "updateMaxTxnAmount",
		"inputs": [
			{
				"type": "uint256",
				"name": "newNum",
				"internalType": "uint256"
			}
		]
	},
	{
		"type": "function",
		"stateMutability": "nonpayable",
		"outputs": [],
		"name": "updateMaxWalletAmount",
		"inputs": [
			{
				"type": "uint256",
				"name": "newNum",
				"internalType": "uint256"
			}
		]
	},
	{
		"type": "function",
		"stateMutability": "nonpayable",
		"outputs": [],
		"name": "updateSellFees",
		"inputs": [
			{
				"type": "uint256",
				"name": "_marketingFee",
				"internalType": "uint256"
			},
			{
				"type": "uint256",
				"name": "_liquidityFee",
				"internalType": "uint256"
			},
			{
				"type": "uint256",
				"name": "_devFee",
				"internalType": "uint256"
			},
			{
				"type": "uint256",
				"name": "_earlySellLiquidityFee",
				"internalType": "uint256"
			},
			{
				"type": "uint256",
				"name": "_earlySellMarketingFee",
				"internalType": "uint256"
			}
		]
	},
	{
		"type": "function",
		"stateMutability": "nonpayable",
		"outputs": [],
		"name": "updateSwapEnabled",
		"inputs": [
			{
				"type": "bool",
				"name": "enabled",
				"internalType": "bool"
			}
		]
	},
	{
		"type": "function",
		"stateMutability": "nonpayable",
		"outputs": [
			{
				"type": "bool",
				"name": "",
				"internalType": "bool"
			}
		],
		"name": "updateSwapTokensAtAmount",
		"inputs": [
			{
				"type": "uint256",
				"name": "newAmount",
				"internalType": "uint256"
			}
		]
	},
	{
		"type": "receive",
		"stateMutability": "payable"
	}
];

const tokenaddress = "0x8BB07ad76ADdE952e83f2876c9bDeA9cc5B3a51E";

/*const tokenABI = [
	{
		"inputs": [],
		"stateMutability": "nonpayable",
		"type": "constructor"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "owner",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "address",
				"name": "spender",
				"type": "address"
			},
			{
				"indexed": false,
				"internalType": "uint256",
				"name": "value",
				"type": "uint256"
			}
		],
		"name": "Approval",
		"type": "event"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "spender",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "amount",
				"type": "uint256"
			}
		],
		"name": "approve",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "amount",
				"type": "uint256"
			}
		],
		"name": "burn",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "account",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "amount",
				"type": "uint256"
			}
		],
		"name": "burnFrom",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "spender",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "subtractedValue",
				"type": "uint256"
			}
		],
		"name": "decreaseAllowance",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "delegatee",
				"type": "address"
			}
		],
		"name": "delegate",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "delegatee",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "nonce",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "expiry",
				"type": "uint256"
			},
			{
				"internalType": "uint8",
				"name": "v",
				"type": "uint8"
			},
			{
				"internalType": "bytes32",
				"name": "r",
				"type": "bytes32"
			},
			{
				"internalType": "bytes32",
				"name": "s",
				"type": "bytes32"
			}
		],
		"name": "delegateBySig",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "delegator",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "address",
				"name": "fromDelegate",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "address",
				"name": "toDelegate",
				"type": "address"
			}
		],
		"name": "DelegateChanged",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "delegate",
				"type": "address"
			},
			{
				"indexed": false,
				"internalType": "uint256",
				"name": "previousBalance",
				"type": "uint256"
			},
			{
				"indexed": false,
				"internalType": "uint256",
				"name": "newBalance",
				"type": "uint256"
			}
		],
		"name": "DelegateVotesChanged",
		"type": "event"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "spender",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "addedValue",
				"type": "uint256"
			}
		],
		"name": "increaseAllowance",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "to",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "amount",
				"type": "uint256"
			}
		],
		"name": "mint",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "previousOwner",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "address",
				"name": "newOwner",
				"type": "address"
			}
		],
		"name": "OwnershipTransferred",
		"type": "event"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "owner",
				"type": "address"
			},
			{
				"internalType": "address",
				"name": "spender",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "value",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "deadline",
				"type": "uint256"
			},
			{
				"internalType": "uint8",
				"name": "v",
				"type": "uint8"
			},
			{
				"internalType": "bytes32",
				"name": "r",
				"type": "bytes32"
			},
			{
				"internalType": "bytes32",
				"name": "s",
				"type": "bytes32"
			}
		],
		"name": "permit",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "renounceOwnership",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "to",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "amount",
				"type": "uint256"
			}
		],
		"name": "transfer",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "from",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "address",
				"name": "to",
				"type": "address"
			},
			{
				"indexed": false,
				"internalType": "uint256",
				"name": "value",
				"type": "uint256"
			}
		],
		"name": "Transfer",
		"type": "event"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "from",
				"type": "address"
			},
			{
				"internalType": "address",
				"name": "to",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "amount",
				"type": "uint256"
			}
		],
		"name": "transferFrom",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "newOwner",
				"type": "address"
			}
		],
		"name": "transferOwnership",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "owner",
				"type": "address"
			},
			{
				"internalType": "address",
				"name": "spender",
				"type": "address"
			}
		],
		"name": "allowance",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "account",
				"type": "address"
			}
		],
		"name": "balanceOf",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "account",
				"type": "address"
			},
			{
				"internalType": "uint32",
				"name": "pos",
				"type": "uint32"
			}
		],
		"name": "checkpoints",
		"outputs": [
			{
				"components": [
					{
						"internalType": "uint32",
						"name": "fromBlock",
						"type": "uint32"
					},
					{
						"internalType": "uint224",
						"name": "votes",
						"type": "uint224"
					}
				],
				"internalType": "struct ERC20Votes.Checkpoint",
				"name": "",
				"type": "tuple"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "decimals",
		"outputs": [
			{
				"internalType": "uint8",
				"name": "",
				"type": "uint8"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "account",
				"type": "address"
			}
		],
		"name": "delegates",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "DOMAIN_SEPARATOR",
		"outputs": [
			{
				"internalType": "bytes32",
				"name": "",
				"type": "bytes32"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "blockNumber",
				"type": "uint256"
			}
		],
		"name": "getPastTotalSupply",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "account",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "blockNumber",
				"type": "uint256"
			}
		],
		"name": "getPastVotes",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "account",
				"type": "address"
			}
		],
		"name": "getVotes",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "name",
		"outputs": [
			{
				"internalType": "string",
				"name": "",
				"type": "string"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "owner",
				"type": "address"
			}
		],
		"name": "nonces",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "account",
				"type": "address"
			}
		],
		"name": "numCheckpoints",
		"outputs": [
			{
				"internalType": "uint32",
				"name": "",
				"type": "uint32"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "owner",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "symbol",
		"outputs": [
			{
				"internalType": "string",
				"name": "",
				"type": "string"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "totalSupply",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	}
];

const tokenaddress = "0x3d3A68Ce8DD368af6133e6505B61fDA470A10396";*/


let contract;
let lotteryContract;
let token_contract;
let lotteryContract2;

class LotteryPOMG extends Component {

	state = {
		walletAddress: "",
		walletAddress2: "",
		totalSupply: "",
		currentPrice: "",
		nextPrice: "",
		nextSessionAmount: "",
		onlyLeftAmount: "",
		statusError: false,
		statusLoading: false,
		success: false,
		nftMintingAmount: '1',
		totalValue: "10000000000000000",
		presaleValue: "0",
		maxmint: '',
		_adminPanel: 0,
		_adminWhitelistAddresses: [],
		_adminWhitelistAddresses2: [],
		_maxTokensToBuy: "",
		_testingValue: '',
		_wlMint: '',
		_FinalResult: '',
		_wlMsg: 'Mint Now',
		_mintStatus: '',
		_cost: '',
		_metadataLink: '',
		_maxPerWallet: '',
		_wl_limit: '',
		_publicSaleCost: '',
		_newOwner: '',
		_owner: '',
		_setWhitelistSign: '',
		_maxPerWallet: '',
		_publicSaleLimit: '',
		_wlClaimed: '',
		stateNow: Date.now(),
		_whitelist_status: '',
		_new_free_allocation: '',
		_whitelist_status2: '',
		_baseURI: '',
		_public_status: '',
		_maxPerWallet: '',
		_pkg1: '',
		_pkg2: '',
		_pkg3: '',
		_pkg4: '',
		_pkg5: '',
		_pkg6: '',
		_drawLottery: '',
		_winners: [],
		_records: [],
		_myTickets: [],
		_myTicketCount: '',
		_maximumTicketCount: '',
		_ticketCount: '',
		_owner2: '',
		_approved1: '',
		_approved2: '',
		_approved3: '',
		_approved4: '',
		_approved5: '',
		_approved6: '',
		_tokenBalance: '',
		_tokenBalance1: '',
		_tokenBalance2: '',
		_tokenBalance3: '',
		_tokenBalance4: '',
		_tokenBalance5: '',
		_tokenBalance6: '',
		_cRecords: [],
		_previousList: 0,
		_cList: 0,
		_previousRecords: []
	}

	async componentDidMount() {
		try {
			if (localStorage?.getItem('isWalletConnected') === 'true') {

				console.log("CONNECTED");

				const providerOptions = {
					walletconnect: {
						package: WalletConnectProvider, // required
						options: {
							infuraId: "bf933c3446b3464c988114813a1360ac" // required
						}
					}
				};

				const web3Modal = new Web3Modal({
					network: "mainnet", // optional
					cacheProvider: true, // optional
					providerOptions // required
				});



				const provider = await web3Modal.connect();

				//  Enable session (triggers QR Code modal)
				await provider.enable();

				const web3 = new Web3(provider);
				console.log("provider : " + provider);
				// Subscribe to accounts change
				provider.on("accountsChanged", (accounts) => {
					console.log(accounts);
				});

				// Subscribe to chainId change
				provider.on("chainChanged", (chainId) => {
					console.log(chainId);
				});

				// Subscribe to provider connection
				provider.on("connect", (info) => {
					console.log(info);
				});

				// Subscribe to provider disconnection
				provider.on("disconnect", (error) => {
					console.log(error);
				});

				// test if wallet is connected
				if (web3Modal.cachedProvider) {
					// connected now you can get accounts
					console.log("provider :" + web3Modal.cachedProvider);
					const accounts = await web3.eth.getAccounts();

					account = accounts[0];
					this.setState({ walletAddress: account });

					contract = new web3.eth.Contract(ABI, address);
					lotteryContract = new web3.eth.Contract(lotteryABI, lotteryaddress);
					token_contract = new web3.eth.Contract(tokenABI, tokenaddress);

					console.log("lotteryContract :" + lotteryContract);

					if (provider) {


						(async () => {


							if (web3Modal.cachedProvider != "walletconnect") {

								try {
									localStorage.setItem('isWalletConnected', true);
								} catch (ex) {
									console.log(ex)
								}

								const chainId = 18159;

								if (window.ethereum.networkVersion !== chainId) {
									try {
										await window.ethereum.request({
											method: 'wallet_switchEthereumChain',
											params: [{ chainId: web3.utils.toHex(chainId) }],
										});
									} catch (err) {
										// This error code indicates that the chain has not been added to MetaMask.
										if (err.code === 4902) {
											await window.ethereum.request({
												method: 'wallet_addEthereumChain',
												params: [
													{
														chainName: 'Proof Of Memes Mainnet',
														chainId: web3.utils.toHex(chainId),
														nativeCurrency: { name: 'Proof Of Memes Mainnet', decimals: 18, symbol: 'POM' },
														rpcUrls: ['https://mainnet-rpc.memescan.io'],

														/*chainName: 'Goerli test network',
														chainId: web3.utils.toHex(chainId),
														nativeCurrency: { name: 'Goerli test network', decimals: 18, symbol: 'GoerliETH' },
														rpcUrls: ['https://goerli.infura.io/v3/']*/
													},
												],
											});
										}
									}
								}

								try {

									try {
										localStorage.setItem('isWalletConnected', true);
									} catch (ex) {
										console.log(ex)
									}
									ticketCount = await lotteryContract.methods.getTicketCount().call();
									this.setState({ _ticketCount: ticketCount });
									console.log("ticketCount :" + ticketCount);

									console.log("WALLET CONNECTED");

									lotteryIndex = await lotteryContract.methods.lotteryIndex().call();
									console.log("lotteryIndex :" + lotteryIndex);

									owner2 = await lotteryContract.methods.owner().call();
									this.setState({ _owner2: owner2 });
									allowance = await token_contract.methods.allowance(account, lotteryaddress).call();
									const bnum1 = BigNumber("1000000000000000000000000");
									const bnum2 = BigNumber("10000000000000000000000000");
									const bnum3 = BigNumber("100000000000000000000000000");
									const bnum4 = BigNumber("250000000000000000000000000");
									const bnum5 = BigNumber("500000000000000000000000000");
									const bnum6 = BigNumber("1000000000000000000000000000");

									if (allowance >= 1000000000000000000000000) {
										approved1 = true;
										this.setState({ _approved1: true });
										console.log("approved1 : " + this.state._approved1);

									} else {
										this.setState({ _approved1: false });
									}

									if (allowance >= 10000000000000000000000000) {
										this.setState({ _approved2: true });
										console.log("approved2 : " + this.state._approved2);

									} else {
										this.setState({ _approved2: false });
									}

									if (allowance > 100000000000000000000000000) {
										this.setState({ _approved3: true });
										console.log("approved3 : " + this.state._approved3);

									} else {
										this.setState({ _approved3: false });
									}

									if (allowance > bnum4) {
										this.setState({ _approved4: true });
										console.log("approved4 : " + this.state._approved4);

									} else {
										this.setState({ _approved4: false });
									}

									if (allowance > bnum5) {
										this.setState({ _approved5: true });
										console.log("approved5 : " + this.state._approved5);

									} else {
										this.setState({ _approved5: false });
									}

									if (allowance > bnum6) {
										this.setState({ _approved6: true });
										console.log("approved6 : " + this.state._approved6);

									} else {
										this.setState({ _approved6: false });
									}

									/*for (let x = 0; x < 110; x++) {
												records[x] = await lotteryContract.methods.getRecord(x).call();
												if (records[x].lotteryIndex == lotteryIndex) {
													this.setState({ _records: records });
													console.log("records :" + records);
												}
											}*/

									allowance = await token_contract.methods.allowance(account, lotteryaddress).call();

									baseURI = await contract.methods.baseURI().call();
									this.setState({ _baseURI: baseURI });
									console.log("baseURI :" + this.state._baseURI);

									public_status = await contract.methods.public_mint_status().call();
									this.setState({ _public_status: public_status });
									console.log("public_status :" + this.state._public_status);

									owner = await contract.methods.owner().call();
									this.setState({ _owner: owner });
									console.log("Owner" + owner);

									totalSupplyNFT = await contract.methods.totalSupply().call();
									this.setState({ totalSupply: totalSupplyNFT });

									console.log("Total Supply:" + totalSupplyNFT);

									publicSale = await contract.methods.balanceOf(account).call();
									this.setState({ myNFTWallet: publicSale });

									cost = await contract.methods.publicSaleCost().call();
									this.setState({ _cost: cost });
									console.log("cost :" + cost);

									owner = await contract.methods.owner().call();
									this.setState({ _owner: owner });
									console.log("Owner" + owner);

									maxPerWallet = await contract.methods.max_per_wallet().call();
									this.setState({ _maxPerWallet: maxPerWallet });
									console.log("maxPerWallet :" + maxPerWallet);

									if (owner == account) {
										console.log("owner : " + owner)
										onlyLeft = 10000 - this.state.totalSupply;

										if (mintAmount > onlyLeft) {
											mintAmount = onlyLeft;
										}

										valueOfNFTs = mintAmount * 0;
										wMintAmount = mintAmount;


										this.setState({ nftMintingAmount: mintAmount });

										this.setState({ totalValue: valueOfNFTs });
									} else {
										mintAmount = 1;

										if (this.state.totalSupply == 10000) {

											onlyLeft = 10000 - this.state.totalSupply;
											mintAmount = onlyLeft;
											this.setState({ msg: "SOLD OUT" });

										} else {
											mintAmount = 1;
											onlyLeft = this.state._maxPerWallet - this.state.myNFTWallet;

											if (mintAmount > onlyLeft) {
												mintAmount = onlyLeft;
											}
											//mintAmount = onlyLeft;

											valueOfNFTs = mintAmount * this.state._cost;
											wMintAmount = mintAmount;


											this.setState({ nftMintingAmount: mintAmount });

											this.setState({ totalValue: valueOfNFTs });
										}
									}

									/*let lotteryIndexNumber = (lotteryIndex * 1) + 1;
									for (let x = 0; x < 110 * lotteryIndexNumber; x++) {
										records[x] = await lotteryContract.methods.records(x).call();
										if (records[x].lotteryIndex == (lotteryIndex * 1) - 1) {
											this.setState({ _records: records });
											console.log("records :" + records);
										}

									}*/

									let cLotteryIndexNumber = (lotteryIndex * 1);
									let difference = 110 + (110 * cLotteryIndexNumber);
									for (let x = 0; x < difference; x++) {
										cRecords[x] = await lotteryContract.methods.records(x).call();

										if (cRecords[x].lotteryIndex == (lotteryIndex * 1)) {
											this.setState({ _cRecords: cRecords });
											console.log("cRecords :" + cRecords);
										}
									}


								} catch (err) {
									console.log("err: " + err);

								}
							} else {

								const chainId = 18159;

								if (WalletConnectProvider.networkVersion !== chainId) {
									try {
										await WalletConnectProvider.request({
											method: 'wallet_switchEthereumChain',
											params: [{ chainId: web3.utils.toHex(chainId) }],
										});
									} catch (err) {
										// This error code indicates that the chain has not been added to MetaMask.
										if (err.code === 4902) {
											await window.ethereum.request({
												method: 'wallet_addEthereumChain',
												params: [
													{
														chainName: 'Proof Of Memes Mainnet',
														chainId: web3.utils.toHex(chainId),
														nativeCurrency: { name: 'Proof Of Memes Mainnet', decimals: 18, symbol: 'POM' },
														rpcUrls: ['https://mainnet-rpc.memescan.io'],

														/*chainName: 'Goerli test network',
														chainId: web3.utils.toHex(chainId),
														nativeCurrency: { name: 'Goerli test network', decimals: 18, symbol: 'GoerliETH' },
														rpcUrls: ['https://goerli.infura.io/v3/']*/
													},
												],
											});
										}
									}
								}




								try {

									try {
										localStorage.setItem('isWalletConnected', true);
									} catch (ex) {
										console.log(ex)
									}

									ticketCount = await lotteryContract.methods.getTicketCount().call();
									this.setState({ _ticketCount: ticketCount });
									console.log("ticketCount :" + ticketCount);

									console.log("WALLET CONNECTED");

									lotteryIndex = await lotteryContract.methods.lotteryIndex().call();
									console.log("lotteryIndex :" + lotteryIndex);

									owner2 = await lotteryContract.methods.owner().call();
									this.setState({ _owner2: owner2 });
									allowance = await token_contract.methods.allowance(account, lotteryaddress).call();
									const bnum1 = BigNumber("1000000000000000000000000");
									const bnum2 = BigNumber("10000000000000000000000000");
									const bnum3 = BigNumber("100000000000000000000000000");
									const bnum4 = BigNumber("250000000000000000");
									const bnum5 = BigNumber("500000000000000000");
									const bnum6 = BigNumber("1000000000000000000");

									if (allowance >= 1000000000000000000000000) {
										approved1 = true;
										this.setState({ _approved1: true });
										console.log("approved1 : " + this.state._approved1);

									} else {
										this.setState({ _approved1: false });
									}

									if (allowance >= 10000000000000000000000000) {
										this.setState({ _approved2: true });
										console.log("approved2 : " + this.state._approved2);

									} else {
										this.setState({ _approved2: false });
									}

									if (allowance > 100000000000000000000000000) {
										this.setState({ _approved3: true });
										console.log("approved3 : " + this.state._approved3);

									} else {
										this.setState({ _approved3: false });
									}

									if (allowance > bnum4) {
										this.setState({ _approved4: true });
										console.log("approved4 : " + this.state._approved4);

									} else {
										this.setState({ _approved4: false });
									}

									if (allowance > bnum5) {
										this.setState({ _approved5: true });
										console.log("approved5 : " + this.state._approved5);

									} else {
										this.setState({ _approved5: false });
									}

									if (allowance > bnum6) {
										this.setState({ _approved6: true });
										console.log("approved6 : " + this.state._approved6);

									} else {
										this.setState({ _approved6: false });
									}

									/*for (let x = 0; x < 110; x++) {
												records[x] = await lotteryContract.methods.getRecord(x).call();
												if (records[x].lotteryIndex == lotteryIndex) {
													this.setState({ _records: records });
													console.log("records :" + records);
												}
											}*/

									allowance = await token_contract.methods.allowance(account, lotteryaddress).call();

									baseURI = await contract.methods.baseURI().call();
									this.setState({ _baseURI: baseURI });
									console.log("baseURI :" + this.state._baseURI);

									public_status = await contract.methods.public_mint_status().call();
									this.setState({ _public_status: public_status });
									console.log("public_status :" + this.state._public_status);

									owner = await contract.methods.owner().call();
									this.setState({ _owner: owner });
									console.log("Owner" + owner);

									totalSupplyNFT = await contract.methods.totalSupply().call();
									this.setState({ totalSupply: totalSupplyNFT });

									console.log("Total Supply:" + totalSupplyNFT);

									publicSale = await contract.methods.balanceOf(account).call();
									this.setState({ myNFTWallet: publicSale });

									cost = await contract.methods.publicSaleCost().call();
									this.setState({ _cost: cost });
									console.log("cost :" + cost);

									owner = await contract.methods.owner().call();
									this.setState({ _owner: owner });
									console.log("Owner" + owner);

									maxPerWallet = await contract.methods.max_per_wallet().call();
									this.setState({ _maxPerWallet: maxPerWallet });
									console.log("maxPerWallet :" + maxPerWallet);

									if (owner == account) {
										console.log("owner : " + owner)
										onlyLeft = 10000 - this.state.totalSupply;

										if (mintAmount > onlyLeft) {
											mintAmount = onlyLeft;
										}

										valueOfNFTs = mintAmount * 0;
										wMintAmount = mintAmount;


										this.setState({ nftMintingAmount: mintAmount });

										this.setState({ totalValue: valueOfNFTs });
									} else {
										mintAmount = 1;

										if (this.state.totalSupply == 10000) {

											onlyLeft = 10000 - this.state.totalSupply;
											mintAmount = onlyLeft;
											this.setState({ msg: "SOLD OUT" });

										} else {
											mintAmount = 1;
											onlyLeft = this.state._maxPerWallet - this.state.myNFTWallet;

											if (mintAmount > onlyLeft) {
												mintAmount = onlyLeft;
											}
											//mintAmount = onlyLeft;

											valueOfNFTs = mintAmount * this.state._cost;
											wMintAmount = mintAmount;


											this.setState({ nftMintingAmount: mintAmount });

											this.setState({ totalValue: valueOfNFTs });
										}
									}


									/*let lotteryIndexNumber = (lotteryIndex * 1) + 1;
									for (let x = 0; x < 110 * lotteryIndexNumber; x++) {
										records[x] = await lotteryContract.methods.records(x).call();
										if (records[x].lotteryIndex == (lotteryIndex * 1) - 1) {
											this.setState({ _records: records });
											console.log("records :" + records);
										}

									}*/

									let cLotteryIndexNumber = (lotteryIndex * 1);
									let difference = 110 + (110 * cLotteryIndexNumber);
									for (let x = 0; x < difference; x++) {
										cRecords[x] = await lotteryContract.methods.records(x).call();

										if (cRecords[x].lotteryIndex == (lotteryIndex * 1)) {
											this.setState({ _cRecords: cRecords });
											console.log("cRecords :" + cRecords);
										}
									}

								} catch (err) {

									console.log("err: " + err);

								}
							}
						})();

						//.....................................................................//

						// Legacy providers may only have ethereum.sendAsync
						const chainId = await provider.request({
							method: 'eth_chainId'
						})

					} else {

						// if the provider is not detected, detectEthereumProvider resolves to null
						console.error('Please install a Valid Wallet');
						alert('A valid provider could not be found!');

					}

				} else {

					console.log("NOT CONNECTED");

				}
			}
		} catch (err) {
			console.log(err);
		}

	}

	onSubmitMinus = async event => {
		event.preventDefault();

		mintAmount = mintAmount - 1;

		if (mintAmount < 1) {
			mintAmount = 1
		}


		if (owner == account) {
			console.log("owner : " + owner)
			onlyLeft = 10000 - this.state.totalSupply;

			if (mintAmount > onlyLeft) {
				mintAmount = onlyLeft;
			}

			valueOfNFTs = mintAmount * 0;
			wMintAmount = mintAmount;

			this.setState({ nftMintingAmount: mintAmount });

			this.setState({ totalValue: valueOfNFTs });
		} else {


			if (this.state.totalSupply < 10000) {

				onlyLeft = this.state._maxPerWallet - this.state.myNFTWallet;

				if (mintAmount > onlyLeft) {
					mintAmount = onlyLeft;
				}
				valueOfNFTs = mintAmount * this.state._cost;
				wMintAmount = mintAmount;

				this.setState({ nftMintingAmount: mintAmount });

				this.setState({ totalValue: valueOfNFTs });

			}
		}
	}

	onSubmitPlus = async event => {
		event.preventDefault();

		//,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,
		mintAmount = mintAmount + 1;

		if (owner == account) {
			console.log("owner : " + owner)
			onlyLeft = 10000 - this.state.totalSupply;

			if (mintAmount > onlyLeft) {
				mintAmount = onlyLeft;
			}

			valueOfNFTs = mintAmount * 0;
			wMintAmount = mintAmount;


			this.setState({ nftMintingAmount: mintAmount });

			this.setState({ totalValue: valueOfNFTs });
		} else {

			if (this.state.totalSupply < 10000) {

				onlyLeft = this.state._maxPerWallet - this.state.myNFTWallet;
				console.log(onlyLeft);

				if (mintAmount > onlyLeft) {
					mintAmount = onlyLeft;
				}
				valueOfNFTs = mintAmount * this.state._cost;
				wMintAmount = mintAmount;

				this.setState({ nftMintingAmount: mintAmount });

				this.setState({ totalValue: valueOfNFTs });

			}
		}
	}

	onSubmit2 = async event => {
		event.preventDefault();

		console.log(this.state.walletAddress);

		try {
			let owner = await contract.methods.owner().call();


			if (account != owner) {


				try {

					console.log("minAmount:" + mintAmount);
					console.log("Cost:" + mintAmount * cost);
					let costV = mintAmount * cost;

					const number = new BigNumber(costV);


					this.setState({ statusError: false, statusLoading: true });
					await contract.methods.mint(mintAmount * 1).send({ gasLimit: 3850000, from: account, value: number });
					this.setState({ statusLoading: false, success: true });
					console.log("Mint Amount :" + this.state.mintAmount);

				} catch (err) {
					this.setState({ errorMassage: "ERROR : " + err.message, statusLoading: false, success: false, statusError: true });
					console.log(err);
				}
			} else {


				try {

					console.log("minAmount:" + mintAmount);
					console.log("valueOfNFTs:" + valueOfNFTs);


					this.setState({ statusError: false, statusLoading: true });
					await contract.methods.mint(mintAmount * 1).send({ gasLimit: 3850000, from: account, value: 0 });
					this.setState({ statusLoading: false, success: true });
					console.log("Mint Amount :" + this.state.mintAmount);

				} catch (err) {
					this.setState({ errorMassage: "ERROR : " + err.message, statusLoading: false, success: false, statusError: true });
					console.log(err);
				}

				console.log("minted successfully");

			}
		} catch (err) {

			console.log(err);

		}
		console.log("minting successfully2");

	}

	approve0 = async event => { 
		event.preventDefault();

		try {

			try {
				const number = new BigNumber('1000000000000000000000000');

				allowance = await token_contract.methods.allowance(account, lotteryaddress).call();

				if (allowance > 0) {

					await token_contract.methods.increaseAllowance(lotteryaddress, '1000000000000000000000000').send({ gasLimit: 6850000, from: account });
					approved1 = true;
					this.setState({ _approved1: true });
					console.log("approved : " + this.state._approved1);
					

				} else {

					await token_contract.methods.approve(lotteryaddress, '1000000000000000000000000').send({ gasLimit: 6850000, from: account });
					approved1 = true;
					this.setState({ _approved1: true });
					console.log("approved : " + this.state._approved1);
				}


			} catch (err) {
				this.setState({ errorMassage: "ERROR : " + err.message, statusLoading: false, success: false, statusError: true });
				console.log(err);

			}


		} catch (err) {

			console.log(err);

		}


	}

	approve1 = async event => {
		event.preventDefault();

		try {

			try {


				/*let value2 = 10000000000000000;

				const number = new BigNumber(value2);

				if (!Number.isFinite(number)) {
					throw new Error('Number is not finite');
				  }
				  
				  if (Math.abs(number) > BigNumber.EXP_LIMIT) {
					throw new Error('Number exceeds maximum exponent size');
				  }*/
				const number = new BigNumber('10000000000000000000000000');

				allowance = await token_contract.methods.allowance(account, lotteryaddress).call();

				if (allowance > 0) {

					await token_contract.methods.increaseAllowance(lotteryaddress, '10000000000000000000000000').send({ gasLimit: 6850000, from: account });

				} else {

					await token_contract.methods.approve(lotteryaddress, '10000000000000000000000000').send({ gasLimit: 6850000, from: account });
				}

				this.setState({ _approved2: true });
				console.log("approved : " + this.state._approved2)


			} catch (err) {
				this.setState({ errorMassage: "ERROR : " + err.message, statusLoading: false, success: false, statusError: true });
				console.log(err);

			}


		} catch (err) {

			console.log(err);

		}


	}

	approve2 = async event => {
		event.preventDefault();

		try {

			try {

				/*let value3 = 100000000000000000;

				const number = new BigNumber(value3);

				if (!Number.isFinite(number)) {
					throw new Error('Number is not finite');
				  }
				  
				  if (Math.abs(number) > BigNumber.EXP_LIMIT) {
					throw new Error('Number exceeds maximum exponent size');
				  }*/
				const number = new BigNumber('100000000000000000000000000');

				allowance = await token_contract.methods.allowance(account, lotteryaddress).call();

				if (allowance > 0) {

					await token_contract.methods.increaseAllowance(lotteryaddress, '100000000000000000000000000').send({ gasLimit: 6850000, from: account });

				} else {

					await token_contract.methods.approve(lotteryaddress, '100000000000000000000000000').send({ gasLimit: 6850000, from: account });
				}

				this.setState({ _approved3: true });
				console.log("approved : " + this.state._approved3)



			} catch (err) {
				this.setState({ errorMassage: "ERROR : " + err.message, statusLoading: false, success: false, statusError: true });
				console.log(err);

			}


		} catch (err) {

			console.log(err);

		}


	}

	approve3 = async event => {
		event.preventDefault();

		try {

			try {

				/*let value3 = 100000000000000000;

				const number = new BigNumber(value3);

				if (!Number.isFinite(number)) {
					throw new Error('Number is not finite');
				  }
				  
				  if (Math.abs(number) > BigNumber.EXP_LIMIT) {
					throw new Error('Number exceeds maximum exponent size');
				  }*/

				allowance = await token_contract.methods.allowance(account, lotteryaddress).call();

				if (allowance > 0) {

					await token_contract.methods.increaseAllowance(lotteryaddress, '250000000000000000').send({ gasLimit: 6850000, from: account });

				} else {

					await token_contract.methods.approve(lotteryaddress, '250000000000000000').send({ gasLimit: 6850000, from: account });
				}

				this.setState({ _approved4: true });
				console.log("approved : " + this.state._approved4)



			} catch (err) {
				this.setState({ errorMassage: "ERROR : " + err.message, statusLoading: false, success: false, statusError: true });
				console.log(err);

			}


		} catch (err) {

			console.log(err);

		}


	}

	approve4 = async event => {
		event.preventDefault();

		try {

			try {

				/*let value3 = 100000000000000000;

				const number = new BigNumber(value3);

				if (!Number.isFinite(number)) {
					throw new Error('Number is not finite');
				  }
				  
				  if (Math.abs(number) > BigNumber.EXP_LIMIT) {
					throw new Error('Number exceeds maximum exponent size');
				  }*/

				allowance = await token_contract.methods.allowance(account, lotteryaddress).call();

				if (allowance > 0) {

					await token_contract.methods.increaseAllowance(lotteryaddress, '500000000000000000').send({ gasLimit: 6850000, from: account });

				} else {

					await token_contract.methods.approve(lotteryaddress, '500000000000000000').send({ gasLimit: 6850000, from: account });
				}

				this.setState({ _approved5: true });
				console.log("approved : " + this.state._approved5)



			} catch (err) {
				this.setState({ errorMassage: "ERROR : " + err.message, statusLoading: false, success: false, statusError: true });
				console.log(err);

			}


		} catch (err) {

			console.log(err);

		}


	}

	approve5 = async event => {
		event.preventDefault();

		try {

			try {

				/*let value3 = 100000000000000000;

				const number = new BigNumber(value3);

				if (!Number.isFinite(number)) {
					throw new Error('Number is not finite');
				  }
				  
				  if (Math.abs(number) > BigNumber.EXP_LIMIT) {
					throw new Error('Number exceeds maximum exponent size');
				  }*/

				allowance = await token_contract.methods.allowance(account, lotteryaddress).call();

				if (allowance > 0) {

					await token_contract.methods.increaseAllowance(lotteryaddress, '1000000000000000000').send({ gasLimit: 6850000, from: account });

				} else {

					await token_contract.methods.approve(lotteryaddress, '1000000000000000000').send({ gasLimit: 6850000, from: account });
				}

				this.setState({ _approved6: true });
				console.log("approved : " + this.state._approved6)



			} catch (err) {
				this.setState({ errorMassage: "ERROR : " + err.message, statusLoading: false, success: false, statusError: true });
				console.log(err);

			}


		} catch (err) {

			console.log(err);

		}


	}

	pkg0 = async event => {
		event.preventDefault();

		try {
			const pkgAmount = new BigNumber('1000000000000000000000000');
											 
			let tokenBalance = await token_contract.methods.balanceOf(account).call();
			console.log("tokenBalance :" + tokenBalance);

			const tokenBalanceBG = new BigNumber(tokenBalance);

			if (tokenBalanceBG >= 1000000000000000000000000) {

				await lotteryContract.methods.pkg0().send({ gasLimit: 3850000, from: account });
				window.location.reload();

				this.setState({ _pkg0: pkg0 });
				console.log("pkg0 :" + pkg0);

			} else {
				this.setState({ _tokenBalance1: "InsufficientFunds" });
				console.log("_tokenBalance : " + tokenBalance);
			}


		} catch (err) {
			console.log(err);
		}

	}

	pkg1 = async event => {
		event.preventDefault();

		try {
			const pkgAmount = new BigNumber('10000000000000000000000000');

			let tokenBalance = await token_contract.methods.balanceOf(account).call();

			if (tokenBalance >= 10000000000000000000000000) {

				await lotteryContract.methods.pkg1().send({ gasLimit: 6850000, from: account });
				window.location.reload();

				this.setState({ _pkg1: pkg1 });
				console.log("pkg0 :" + pkg1);

			} else {
				this.setState({ _tokenBalance2: "InsufficientFunds" });

			}

		} catch (err) {
			console.log(err);
		}

	}

	pkg2 = async event => {
		event.preventDefault();

		try {
			const pkgAmount = new BigNumber('100000000000000000000000000');

			let tokenBalance = await token_contract.methods.balanceOf(account).call();

			if (tokenBalance >= 100000000000000000000000000) {

				await lotteryContract.methods.pkg2().send({ gasLimit: 6850000, from: account });
				window.location.reload();

				this.setState({ _pkg2: pkg2 });
				console.log("pkg0 :" + pkg2);

			} else {
				this.setState({ _tokenBalance3: "InsufficientFunds" });
			}



		} catch (err) {
			console.log(err);
		}

	}

	pkg3 = async event => {
		event.preventDefault();

		try {
			const pkgAmount = new BigNumber('250000000000000000000000000');

			let tokenBalance = await token_contract.methods.balanceOf(account).call();

			if (tokenBalance >= pkgAmount) {

				await lotteryContract.methods.pkg3().send({ gasLimit: 6850000, from: account });
				window.location.reload();

				this.setState({ _pkg3: pkg3 });
				console.log("pkg3 :" + pkg3);

			} else {
				this.setState({ _tokenBalance4: "InsufficientFunds" });
			}



		} catch (err) {
			console.log(err);
		}

	}

	pkg4 = async event => {
		event.preventDefault();

		try {
			const pkgAmount = new BigNumber('500000000000000000000000000');

			let tokenBalance = await token_contract.methods.balanceOf(account).call();

			if (tokenBalance >= pkgAmount) {

				await lotteryContract.methods.pkg4().send({ gasLimit: 6850000, from: account });
				window.location.reload();

				this.setState({ _pkg4: pkg4 });
				console.log("pkg4 :" + pkg4);

			} else {
				this.setState({ _tokenBalance5: "InsufficientFunds" });
			}



		} catch (err) {
			console.log(err);
		}

	}

	pkg5 = async event => {
		event.preventDefault();

		try {
			const pkgAmount = new BigNumber('1000000000000000000000000000');

			let tokenBalance = await token_contract.methods.balanceOf(account).call();

			if (tokenBalance >= pkgAmount) {

				await lotteryContract.methods.pkg5().send({ gasLimit: 6850000, from: account });
				window.location.reload();

				this.setState({ _pkg5: pkg5 });
				console.log("pkg5 :" + pkg5);

			} else {
				this.setState({ _tokenBalance6: "InsufficientFunds" });
			}



		} catch (err) {
			console.log(err);
		}

	}

	myTickets = async event => {
		event.preventDefault();

		try {

			myTicketCount = await lotteryContract.methods.myTicketCount(account, lotteryIndex).call();
			this.setState({ _myTicketCount: myTicketCount });

			for (let x = 0; x < myTicketCount; x++) {
				myTickets[x] = await lotteryContract.methods.myTickets(account, lotteryIndex, x).call();

				this.setState({ _myTickets: myTickets });
				console.log("myTickets :" + myTickets);
			}

		} catch (err) {
			console.log(err);
		}
	}

	drawLottery = async event => {
		event.preventDefault();

		try {

			await lotteryContract.methods.drawLottery().send({ gasLimit: 3850000, from: account });
			window.location.reload();
			this.setState({ _drawLottery: drawLottery });
			console.log("drawLottery :" + drawLottery);

		} catch (err) {
			console.log(err);
		}

	}

	walletConnect = async event => {
		event.preventDefault();

		const providerOptions = {
			walletconnect: {
				package: WalletConnectProvider, // required
				options: {
					infuraId: "bf933c3446b3464c988114813a1360ac" // required
				}
			}
		};

		const web3Modal = new Web3Modal({
			network: "mainnet", // optional
			cacheProvider: true, // optional
			providerOptions // required
		});



		const provider = await web3Modal.connect();

		//  Enable session (triggers QR Code modal)
		await provider.enable();

		const web3 = new Web3(provider);
		console.log("provider : " + provider);
		// Subscribe to accounts change
		provider.on("accountsChanged", (accounts) => {
			console.log(accounts);
		});

		// Subscribe to chainId change
		provider.on("chainChanged", (chainId) => {
			console.log(chainId);
		});

		// Subscribe to provider connection
		provider.on("connect", (info) => {
			console.log(info);
		});

		// Subscribe to provider disconnection
		provider.on("disconnect", (error) => {
			console.log(error);
		});

		// test if wallet is connected
		if (web3Modal.cachedProvider) {
			// connected now you can get accounts
			console.log("provider :" + web3Modal.cachedProvider);
			const accounts = await web3.eth.getAccounts();

			account = accounts[0];
			this.setState({ walletAddress: account });

			contract = new web3.eth.Contract(ABI, address);
			lotteryContract = new web3.eth.Contract(lotteryABI, lotteryaddress);
			token_contract = new web3.eth.Contract(tokenABI, tokenaddress);

			console.log("lotteryContract :" + lotteryContract);

			if (provider) {


				(async () => {


					if (web3Modal.cachedProvider != "walletconnect") {

						try {
							localStorage.setItem('isWalletConnected', true);
						} catch (ex) {
							console.log(ex)
						}

						const chainId = 18159;

						if (window.ethereum.networkVersion !== chainId) {
							try {
								await window.ethereum.request({
									method: 'wallet_switchEthereumChain',
									params: [{ chainId: web3.utils.toHex(chainId) }],
								});
							} catch (err) {
								// This error code indicates that the chain has not been added to MetaMask.
								if (err.code === 4902) {
									await window.ethereum.request({
										method: 'wallet_addEthereumChain',
										params: [
											{
												chainName: 'Proof Of Memes Mainnet',
												chainId: web3.utils.toHex(chainId),
												nativeCurrency: { name: 'Proof Of Memes Mainnet', decimals: 18, symbol: 'POM' },
												rpcUrls: ['https://mainnet-rpc.memescan.io'],

												/*chainName: 'Goerli test network',
												chainId: web3.utils.toHex(chainId),
												nativeCurrency: { name: 'Goerli test network', decimals: 18, symbol: 'GoerliETH' },
												rpcUrls: ['https://goerli.infura.io/v3/']*/
											},
										],
									});
								}
							}
						}

						try {

							try {
								localStorage.setItem('isWalletConnected', true);
							} catch (ex) {
								console.log(ex)
							}

							ticketCount = await lotteryContract.methods.getTicketCount().call();
							this.setState({ _ticketCount: ticketCount });
							console.log("ticketCount :" + ticketCount);

							console.log("WALLET CONNECTED");

							lotteryIndex = await lotteryContract.methods.lotteryIndex().call();
							console.log("lotteryIndex :" + lotteryIndex);

							owner2 = await lotteryContract.methods.owner().call();
							this.setState({ _owner2: owner2 });
							allowance = await token_contract.methods.allowance(account, lotteryaddress).call();
							const bnum1 = BigNumber("1000000000000000000000000");
							const bnum2 = BigNumber("10000000000000000000000000");
							const bnum3 = BigNumber("100000000000000000000000000");
							const bnum4 = BigNumber("250000000000000000");
							const bnum5 = BigNumber("500000000000000000");
							const bnum6 = BigNumber("1000000000000000000");

							if (allowance >= 1000000000000000000000000) {
								approved1 = true;
								this.setState({ _approved1: true });
								console.log("approved1 : " + this.state._approved1);

							} else {
								this.setState({ _approved1: false });
							}

							if (allowance >= 10000000000000000000000000) {
								this.setState({ _approved2: true });
								console.log("approved2 : " + this.state._approved2);

							} else {
								this.setState({ _approved2: false });
							}

							if (allowance > 100000000000000000000000000) {
								this.setState({ _approved3: true });
								console.log("approved3 : " + this.state._approved3);

							} else {
								this.setState({ _approved3: false });
							}

							if (allowance > bnum4) {
								this.setState({ _approved4: true });
								console.log("approved4 : " + this.state._approved4);

							} else {
								this.setState({ _approved4: false });
							}

							if (allowance > bnum5) {
								this.setState({ _approved5: true });
								console.log("approved5 : " + this.state._approved5);

							} else {
								this.setState({ _approved5: false });
							}

							if (allowance > bnum6) {
								this.setState({ _approved6: true });
								console.log("approved6 : " + this.state._approved6);

							} else {
								this.setState({ _approved6: false });
							}

							/*for (let x = 0; x < 110; x++) {
										records[x] = await lotteryContract.methods.getRecord(x).call();
										if (records[x].lotteryIndex == lotteryIndex) {
											this.setState({ _records: records });
											console.log("records :" + records);
										}
									}*/

							allowance = await token_contract.methods.allowance(account, lotteryaddress).call();

							baseURI = await contract.methods.baseURI().call();
							this.setState({ _baseURI: baseURI });
							console.log("baseURI :" + this.state._baseURI);

							public_status = await contract.methods.public_mint_status().call();
							this.setState({ _public_status: public_status });
							console.log("public_status :" + this.state._public_status);

							owner = await contract.methods.owner().call();
							this.setState({ _owner: owner });
							console.log("Owner" + owner);

							totalSupplyNFT = await contract.methods.totalSupply().call();
							this.setState({ totalSupply: totalSupplyNFT });

							console.log("Total Supply:" + totalSupplyNFT);

							publicSale = await contract.methods.balanceOf(account).call();
							this.setState({ myNFTWallet: publicSale });

							cost = await contract.methods.publicSaleCost().call();
							this.setState({ _cost: cost });
							console.log("cost :" + cost);

							owner = await contract.methods.owner().call();
							this.setState({ _owner: owner });
							console.log("Owner" + owner);

							maxPerWallet = await contract.methods.max_per_wallet().call();
							this.setState({ _maxPerWallet: maxPerWallet });
							console.log("maxPerWallet :" + maxPerWallet);

							if (owner == account) {
								console.log("owner : " + owner)
								onlyLeft = 10000 - this.state.totalSupply;

								if (mintAmount > onlyLeft) {
									mintAmount = onlyLeft;
								}

								valueOfNFTs = mintAmount * 0;
								wMintAmount = mintAmount;


								this.setState({ nftMintingAmount: mintAmount });

								this.setState({ totalValue: valueOfNFTs });
							} else {
								mintAmount = 1;

								if (this.state.totalSupply == 10000) {

									onlyLeft = 10000 - this.state.totalSupply;
									mintAmount = onlyLeft;
									this.setState({ msg: "SOLD OUT" });

								} else {
									mintAmount = 1;
									onlyLeft = this.state._maxPerWallet - this.state.myNFTWallet;

									if (mintAmount > onlyLeft) {
										mintAmount = onlyLeft;
									}
									//mintAmount = onlyLeft;

									valueOfNFTs = mintAmount * this.state._cost;
									wMintAmount = mintAmount;


									this.setState({ nftMintingAmount: mintAmount });

									this.setState({ totalValue: valueOfNFTs });
								}
							}

							let cLotteryIndexNumber = (lotteryIndex * 1);
							let difference = 110 + (110 * cLotteryIndexNumber);
							for (let x = 0; x < difference; x++) {
								cRecords[x] = await lotteryContract.methods.records(x).call();

								if (cRecords[x].lotteryIndex == (lotteryIndex * 1)) {
									this.setState({ _cRecords: cRecords });
									console.log("cRecords :" + cRecords);
								}
							}


						} catch (err) {
							console.log("err: " + err);

						}
					} else {

						const chainId = 18159;

						if (WalletConnectProvider.networkVersion !== chainId) {
							try {
								await WalletConnectProvider.request({
									method: 'wallet_switchEthereumChain',
									params: [{ chainId: web3.utils.toHex(chainId) }],
								});
							} catch (err) {
								// This error code indicates that the chain has not been added to MetaMask.
								if (err.code === 4902) {
									await window.ethereum.request({
										method: 'wallet_addEthereumChain',
										params: [
											{
												chainName: 'Proof Of Memes Mainnet',
												chainId: web3.utils.toHex(chainId),
												nativeCurrency: { name: 'Proof Of Memes Mainnet', decimals: 18, symbol: 'POM' },
												rpcUrls: ['https://mainnet-rpc.memescan.io'],

												/*chainName: 'Goerli test network',
												chainId: web3.utils.toHex(chainId),
												nativeCurrency: { name: 'Goerli test network', decimals: 18, symbol: 'GoerliETH' },
												rpcUrls: ['https://goerli.infura.io/v3/']*/
											},
										],
									});
								}
							}
						}

						try {

							try {
								localStorage.setItem('isWalletConnected', true);
							} catch (ex) {
								console.log(ex)
							}

							ticketCount = await lotteryContract.methods.getTicketCount().call();
							this.setState({ _ticketCount: ticketCount });
							console.log("ticketCount :" + ticketCount);

							console.log("WALLET CONNECTED");

							lotteryIndex = await lotteryContract.methods.lotteryIndex().call();
							console.log("lotteryIndex :" + lotteryIndex);

							owner2 = await lotteryContract.methods.owner().call();
							this.setState({ _owner2: owner2 });
							allowance = await token_contract.methods.allowance(account, lotteryaddress).call();
							const bnum1 = BigNumber("1000000000000000000000000");
							const bnum2 = BigNumber("10000000000000000000000000");
							const bnum3 = BigNumber("100000000000000000000000000");
							const bnum4 = BigNumber("250000000000000000");
							const bnum5 = BigNumber("500000000000000000");
							const bnum6 = BigNumber("1000000000000000000");

							if (allowance >= 1000000000000000000000000) {
								approved1 = true;
								this.setState({ _approved1: true });
								console.log("approved1 : " + this.state._approved1);

							} else {
								this.setState({ _approved1: false });
							}

							if (allowance >= 10000000000000000000000000) {
								this.setState({ _approved2: true });
								console.log("approved2 : " + this.state._approved2);

							} else {
								this.setState({ _approved2: false });
							}

							if (allowance > 100000000000000000000000000) {
								this.setState({ _approved3: true });
								console.log("approved3 : " + this.state._approved3);

							} else {
								this.setState({ _approved3: false });
							}

							if (allowance > bnum4) {
								this.setState({ _approved4: true });
								console.log("approved4 : " + this.state._approved4);

							} else {
								this.setState({ _approved4: false });
							}

							if (allowance > bnum5) {
								this.setState({ _approved5: true });
								console.log("approved5 : " + this.state._approved5);

							} else {
								this.setState({ _approved5: false });
							}

							if (allowance > bnum6) {
								this.setState({ _approved6: true });
								console.log("approved6 : " + this.state._approved6);

							} else {
								this.setState({ _approved6: false });
							}

							/*for (let x = 0; x < 110; x++) {
										records[x] = await lotteryContract.methods.getRecord(x).call();
										if (records[x].lotteryIndex == lotteryIndex) {
											this.setState({ _records: records });
											console.log("records :" + records);
										}
									}*/

							allowance = await token_contract.methods.allowance(account, lotteryaddress).call();

							public_status = await contract.methods.public_mint_status().call();
							this.setState({ _public_status: public_status });
							console.log("baseURI :" + this.state._public_status);

							owner = await contract.methods.owner().call();
							this.setState({ _owner: owner });
							console.log("Owner" + owner);

							totalSupplyNFT = await contract.methods.totalSupply().call();
							this.setState({ totalSupply: totalSupplyNFT });

							console.log("Total Supply:" + totalSupplyNFT);

							publicSale = await contract.methods.balanceOf(account).call();
							this.setState({ myNFTWallet: publicSale });

							cost = await contract.methods.publicSaleCost().call();
							this.setState({ _cost: cost });
							console.log("cost :" + cost);


							maxPerWallet = await contract.methods.max_per_wallet().call();
							this.setState({ _maxPerWallet: maxPerWallet });
							console.log("maxPerWallet :" + maxPerWallet);

							if (owner == account) {
								console.log("owner : " + owner)
								onlyLeft = 10000 - this.state.totalSupply;

								if (mintAmount > onlyLeft) {
									mintAmount = onlyLeft;
								}

								valueOfNFTs = mintAmount * 0;

								wMintAmount = mintAmount;


								this.setState({ nftMintingAmount: mintAmount });

								this.setState({ totalValue: valueOfNFTs });
							} else {
								mintAmount = 1;

								if (this.state.totalSupply == 10000) {

									onlyLeft = 0;

									if (mintAmount > onlyLeft) {
										mintAmount = onlyLeft;
									}
									this.setState({ msg: "SOLD OUT" });

								} else {
									mintAmount = 1;
									onlyLeft = this.state._maxPerWallet - publicSale;

									if (mintAmount > onlyLeft) {
										mintAmount = onlyLeft;
									}

									valueOfNFTs = mintAmount * this.state._cost;
									wMintAmount = mintAmount;


									this.setState({ nftMintingAmount: mintAmount });

									this.setState({ totalValue: valueOfNFTs });
								}
							}


							/*let lotteryIndexNumber = (lotteryIndex * 1) + 1;
							for (let x = 0; x < 110 * lotteryIndexNumber; x++) {
								records[x] = await lotteryContract.methods.records(x).call();
								if (records[x].lotteryIndex == (lotteryIndex * 1) - 1) {
									this.setState({ _records: records });
									console.log("records :" + records);
								}

							}*/

							let cLotteryIndexNumber = (lotteryIndex * 1);
							let difference = 110 + (110 * cLotteryIndexNumber);
							for (let x = 0; x < difference; x++) {
								cRecords[x] = await lotteryContract.methods.records(x).call();

								if (cRecords[x].lotteryIndex == (lotteryIndex * 1)) {
									this.setState({ _cRecords: cRecords });
									console.log("cRecords :" + cRecords);
								}
							}



						} catch (err) {
							console.log("err: " + err);

						}
					}
				})();

				//.....................................................................//






				// Legacy providers may only have ethereum.sendAsync
				const chainId = await provider.request({
					method: 'eth_chainId'
				})

			} else {

				// if the provider is not detected, detectEthereumProvider resolves to null
				console.error('Please install a Valid Wallet');
				alert('A valid provider could not be found!');

			}






		}

	}

	previousWinnersList = async event => {
		event.preventDefault();

		this.setState({ _previousList: 1 });
		console.log("_previousList :" + this.state._previousList);

		let y = 0;
		let lotteryIndexNumber = (lotteryIndex * 1) + 1;
		for (let x = lotteryIndex; x < 110 * lotteryIndexNumber; x++) {
			records[x] = await lotteryContract.methods.records(x).call();
			if (records[x].lotteryIndex == (lotteryIndex * 1) - 1) {

				previousRecords[y] = records[x];

				this.setState({ _previousRecords: previousRecords });
				console.log("previousRecords :" + previousRecords[y].lotteryIndex);
				y++;
			}

		}

	}

	currentWinnersList = async event => {

		this.setState({ _cList: 1 });

		let cLotteryIndexNumber = (lotteryIndex * 1);
		let difference = 110 + (110 * cLotteryIndexNumber);
		for (let x = 0; x < difference; x++) {
			cRecords[x] = await lotteryContract.methods.records(x).call();

			if (cRecords[x].lotteryIndex == (lotteryIndex * 1)) {
				this.setState({ _cRecords: cRecords });
				console.log("cRecords :" + cRecords);
			}
		}
	}

	walletConnect2 = async event => {
		event.preventDefault();

		const providerOptions = {
			walletconnect: {
				package: WalletConnectProvider, // required
				options: {
					infuraId: "bf933c3446b3464c988114813a1360ac" // required
				}
			}
		};

		const web3Modal = new Web3Modal({
			network: "mainnet", // optional
			cacheProvider: true, // optional
			providerOptions // required
		});



		const provider = await web3Modal.connect();

		//  Enable session (triggers QR Code modal)
		await provider.enable();

		const web3 = new Web3(provider);
		console.log("provider : " + provider);
		// Subscribe to accounts change
		provider.on("accountsChanged", (accounts) => {
			console.log(accounts);
		});

		// Subscribe to chainId change
		provider.on("chainChanged", (chainId) => {
			console.log(chainId);
		});

		// Subscribe to provider connection
		provider.on("connect", (info) => {
			console.log(info);
		});

		// Subscribe to provider disconnection
		provider.on("disconnect", (error) => {
			console.log(error);
		});

		// test if wallet is connected
		if (web3Modal.cachedProvider) {
			// connected now you can get accounts
			console.log("provider :" + web3Modal.cachedProvider);
			const accounts = await web3.eth.getAccounts();

			account = accounts[0];
			this.setState({ walletAddress2: account });

			contract = new web3.eth.Contract(ABI, address);
			lotteryContract = new web3.eth.Contract(lotteryABI, lotteryaddress);
			token_contract = new web3.eth.Contract(tokenABI, tokenaddress);

			console.log("lotteryContract :" + lotteryContract);

			if (provider) {


				(async () => {


					if (web3Modal.cachedProvider != "walletconnect") {

						try {
							localStorage.setItem('isWalletConnected', true);
						} catch (ex) {
							console.log(ex)
						}

						const chainId = 18159;

						if (window.ethereum.networkVersion !== chainId) {
							try {
								await window.ethereum.request({
									method: 'wallet_switchEthereumChain',
									params: [{ chainId: web3.utils.toHex(chainId) }],
								});
							} catch (err) {
								// This error code indicates that the chain has not been added to MetaMask.
								if (err.code === 4902) {
									await window.ethereum.request({
										method: 'wallet_addEthereumChain',
										params: [
											{
												chainName: 'Proof Of Memes Mainnet',
												chainId: web3.utils.toHex(chainId),
												nativeCurrency: { name: 'Proof Of Memes Mainnet', decimals: 18, symbol: 'POM' },
												rpcUrls: ['https://mainnet-rpc.memescan.io'],

												/*chainName: 'Goerli test network',
												chainId: web3.utils.toHex(chainId),
												nativeCurrency: { name: 'Goerli test network', decimals: 18, symbol: 'GoerliETH' },
												rpcUrls: ['https://goerli.infura.io/v3/']*/
											},
										],
									});
								}
							}
						}

						try {

							try {
								localStorage.setItem('isWalletConnected', true);
							} catch (ex) {
								console.log(ex)
							}

							/*for (let x = 0; x <= drawCount; x++) {
							winners[x] = await lotteryContract.methods.winners(x).call();
							this.setState({ _winners: winners });
							console.log("winners :" + winners);
	
						}*/

							//lotteryIndex = await lotteryContract.methods.lotteryIndex().call();
							ticketCount = await lotteryContract.methods.getTicketCount().call();
							this.setState({ _ticketCount: ticketCount });
							console.log("ticketCount :" + ticketCount);

							console.log("WALLET CONNECTED");

							lotteryIndex = await lotteryContract.methods.lotteryIndex().call();
							console.log("lotteryIndex :" + lotteryIndex);

							owner2 = await lotteryContract.methods.owner().call();
							this.setState({ _owner2: owner2 });

							allowance = await token_contract.methods.allowance(account, lotteryaddress).call();

							const bnum1 = BigNumber("1000000000000000000000000");
							const bnum2 = BigNumber("10000000000000000000000000");
							const bnum3 = BigNumber("100000000000000000000000000");
							const bnum4 = BigNumber("250000000000000000");
							const bnum5 = BigNumber("500000000000000000");
							const bnum6 = BigNumber("1000000000000000000");

							if (allowance >= 1000000000000000000000000) {
								approved1 = true;
								this.setState({ _approved1: true });
								console.log("approved1 : " + this.state._approved1);

							} else {
								this.setState({ _approved1: false });
							}

							if (allowance >= 10000000000000000000000000) {
								this.setState({ _approved2: true });
								console.log("approved2 : " + this.state._approved2);

							} else {
								this.setState({ _approved2: false });
							}

							if (allowance > 100000000000000000000000000) {
								this.setState({ _approved3: true });
								console.log("approved3 : " + this.state._approved3);

							} else {
								this.setState({ _approved3: false });
							}

							if (allowance > bnum4) {
								this.setState({ _approved4: true });
								console.log("approved4 : " + this.state._approved4);

							} else {
								this.setState({ _approved4: false });
							}

							if (allowance > bnum5) {
								this.setState({ _approved5: true });
								console.log("approved5 : " + this.state._approved5);

							} else {
								this.setState({ _approved5: false });
							}

							if (allowance > bnum6) {
								this.setState({ _approved6: true });
								console.log("approved6 : " + this.state._approved6);

							} else {
								this.setState({ _approved6: false });
							}

							/*for (let x = 0; x < 110; x++) {
										records[x] = await lotteryContract.methods.getRecord(x).call();
										if (records[x].lotteryIndex == lotteryIndex) {
											this.setState({ _records: records });
											console.log("records :" + records);
										}
									}*/

							for (let x = 0; x < 110; x++) {
								records[x] = await lotteryContract.methods.records(x).call();
								if (records[x].lotteryIndex == (lotteryIndex * 1) - 1) {
									this.setState({ _records: records });
									console.log("records :" + records);
								}
							}


						} catch (err) {
							console.log("err: " + err);

						}
					} else {

						const chainId = 18159;

						if (WalletConnectProvider.networkVersion !== chainId) {
							try {
								await WalletConnectProvider.request({
									method: 'wallet_switchEthereumChain',
									params: [{ chainId: web3.utils.toHex(chainId) }],
								});
							} catch (err) {
								// This error code indicates that the chain has not been added to MetaMask.
								if (err.code === 4902) {
									await window.ethereum.request({
										method: 'wallet_addEthereumChain',
										params: [
											{
												chainName: 'Proof Of Memes Mainnet',
												chainId: web3.utils.toHex(chainId),
												nativeCurrency: { name: 'Proof Of Memes Mainnet', decimals: 18, symbol: 'POM' },
												rpcUrls: ['https://mainnet-rpc.memescan.io'],

												/*chainName: 'Goerli test network',
												chainId: web3.utils.toHex(chainId),
												nativeCurrency: { name: 'Goerli test network', decimals: 18, symbol: 'GoerliETH' },
												rpcUrls: ['https://goerli.infura.io/v3/']*/
											},
										],
									});
								}
							}
						}




						try {

							try {
								localStorage.setItem('isWalletConnected', true);
							} catch (ex) {
								console.log(ex)
							}

							/*for (let x = 0; x <= drawCount; x++) {
							winners[x] = await lotteryContract.methods.winners(x).call();
							this.setState({ _winners: winners });
							console.log("winners :" + winners);
	
						}*/

							//	(async () => {

							//lotteryIndex = await lotteryContract.methods.lotteryIndex().call();
							let ticketCount2 = await lotteryContract.methods.getTicketCount().call();
							this.setState({ _ticketCount: ticketCount2 });
							console.log("ticketCount :" + ticketCount2);
							//})

							console.log("WALLET CONNECTED");

							lotteryIndex = await lotteryContract.methods.lotteryIndex().call();
							console.log("lotteryIndex :" + lotteryIndex);

							owner2 = await lotteryContract.methods.owner().call();
							this.setState({ _owner2: owner2 });

							allowance = await token_contract.methods.allowance(account, lotteryaddress).call();

							const bnum1 = BigNumber("1000000000000000000000000");
							const bnum2 = BigNumber("10000000000000000000000000");
							const bnum3 = BigNumber("100000000000000000000000000");
							const bnum4 = BigNumber("250000000000000000");
							const bnum5 = BigNumber("500000000000000000");
							const bnum6 = BigNumber("1000000000000000000");

							if (allowance >= 1000000000000000000000000) {
								approved1 = true;								
								this.setState({ _approved1: true });
								console.log("approved1 : " + this.state._approved1);

							} else {
								this.setState({ _approved1: false });
							}

							if (allowance >= 10000000000000000000000000) {
								this.setState({ _approved2: true });
								console.log("approved2 : " + this.state._approved2);

							} else {
								this.setState({ _approved2: false });
							}

							if (allowance > 100000000000000000000000000) {
								this.setState({ _approved3: true });
								console.log("approved3 : " + this.state._approved3);

							} else {
								this.setState({ _approved3: false });
							}

							if (allowance > bnum4) {
								this.setState({ _approved4: true });
								console.log("approved4 : " + this.state._approved4);

							} else {
								this.setState({ _approved4: false });
							}

							if (allowance > bnum5) {
								this.setState({ _approved5: true });
								console.log("approved5 : " + this.state._approved5);

							} else {
								this.setState({ _approved5: false });
							}

							if (allowance > bnum6) {
								this.setState({ _approved6: true });
								console.log("approved6 : " + this.state._approved6);

							} else {
								this.setState({ _approved6: false });
							}

							/*for (let x = 0; x < 110; x++) {
										records[x] = await lotteryContract.methods.getRecord(x).call();
										if (records[x].lotteryIndex == lotteryIndex) {
											this.setState({ _records: records });
											console.log("records :" + records);
										}
									}*/

							for (let x = 0; x < 110; x++) {
								records[x] = await lotteryContract.methods.records(x).call();
								if (records[x].lotteryIndex == (lotteryIndex * 1) - 1) {
									this.setState({ _records: records });
									console.log("records :" + records);
								}
							}


						} catch (err) {

							console.log("err: " + err);

						}
					}
				})();

				//.....................................................................//

				// Legacy providers may only have ethereum.sendAsync
				const chainId = await provider.request({
					method: 'eth_chainId'
				})

			} else {

				// if the provider is not detected, detectEthereumProvider resolves to null
				console.error('Please install a Valid Wallet');
				alert('A valid provider could not be found!');

			}

		}

	}

	walletDisconnect = async event => {
		event.preventDefault();


		const providerOptions = {
			walletconnect: {
				package: WalletConnectProvider, // required
				options: {
					infuraId: "bf933c3446b3464c988114813a1360ac" // required
				}
			}
		};

		const web3Modal = new Web3Modal({
			network: "mainnet", // optional
			cacheProvider: true, // optional
			providerOptions // required
		});



		// disconnect wallet
		web3Modal.clearCachedProvider();
		window.location.reload();

		try {
			localStorage.setItem('isWalletConnected', false)
		} catch (ex) {
			console.log(ex)
		}

	}

	render() {

		return (

			<div class="allWrap">
				<div class="light">

					<div class="headers">

						<div class="h1">
							<div><img class="logo" src={logo} /></div>
							<div ><Link activeClass="" id="fontSize2" spy={true} smooth={true} duration={550} onClick={() => window.location.href = '/'}>Home</Link></div>

							<div class="connect">
								<div ><Link activeClass="" id="fontSize" spy={true} smooth={true} duration={550} onClick={() => window.location.href = '/'}>Home</Link></div>
							</div>

						</div>

					</div>

					<Element name="lottery2">
						<div class="boxWrap2Lottery">

							<p class="utilH">Lottery</p>
							<p class="storyH2">POMG LOTTERY</p>
							<div class="walletAndMint">
								{this.state.walletAddress === '' ?
									(<form class="stakeWCMain" onSubmit={this.walletConnect}>
										<button class="kave-btn">
											<span class="kave-line"></span>
											Connect To Proceed
										</button>
									</form>) : (<form class="stakeWCMain" onSubmit={this.walletDisconnect}><button class="kave-btn">
										<span class="kave-line"></span>
										{this.state.walletAddress.slice(0, 3) + "..." + this.state.walletAddress.slice(39, 42)}

									</button>

									</form>)}
							</div>
							<div class="lotteryMain">
								<div class="lotterySubH">Buy Tickets</div>


								<div class="lotteryMain2-2">

									<div class="lotteryMain4">
										<div class="ticketsSeparately">
											<img class="lottery" src={lottery} />
											{approved1 ?

												(<div class="ticketCategory"><button class="button-29" onClick={this.pkg0}>1 Ticket</button></div>) :
												(<div class="ticketCategory"><button class="button-29" onClick={this.approve0}>Approve 1 Ticket</button></div>)}
											<div class="pomPrice">1Mn POMG Tokens</div>
											<div class="pomPrice">{this.state._tokenBalance1}</div>

										</div>

										<div class="ticketsSeparately">
											<img class="lottery" src={lottery2} />
											{this.state._approved2 ?
												(<div class="ticketCategory"><button class="button-29" onClick={this.pkg1}>10 Tickets</button></div>) :
												(<div class="ticketCategory"><button class="button-29" onClick={this.approve1}>Approve 10 Tickets</button></div>)}
											<div class="pomPrice">10Mn POMG Tokens</div>
											<div class="pomPrice">{this.state._tokenBalance2}</div>

										</div>

										<div class="ticketsSeparately">
											<img class="lottery" src={lottery3} />
											{this.state._approved3 ?
												(<div class="ticketCategory"><button class="button-29" onClick={this.pkg2}>100 Tickets</button></div>) :
												(<div class="ticketCategory"><button class="button-29" onClick={this.approve2}>Approve 100 Tickets</button></div>)}

											<div class="pomPrice">100Mn POMG Tokens</div>
											<div class="pomPrice">{this.state._tokenBalance3}</div>

										</div>
									</div>

								</div>
							</div>

							<div class="totalTickets">{this.state._ticketCount} / 1000 Tickets Sold</div>

							{this.state.walletAddress === owner2 ?
								(<div class="accordin reveal">
									<details >
										<summary>Draw Lottery</summary>
										<div class="faq__content">
											<p><button class="drawBtn" onClick={this.drawLottery}>Draw Lottery</button>

											</p>
										</div>
									</details>
								</div>) : null}

							<div class="accordin reveal">
								<details >
									<summary>Show My Tickets  {this.state._myTicketCount}</summary>
									<div class="faq__content">
										<p><button class="drawBtn" onClick={this.myTickets}>Reveal</button>
											<div class="rowIds">{this.state._myTickets.map((element) => {
												return (
													<div class="ticketReveal"><span class="elementCss">{element}</span> </div>
												)
											})}</div>
										</p>
									</div>
								</details>
							</div>

							<div class="accordin reveal">
								<details >
									<summary>Previous Winners list</summary>
									<div class="faq__content">
										<p><button class="drawBtn" onClick={this.previousWinnersList}>Show Previous Winners list</button>

										</p>
									</div>
								</details>
							</div>

							<div class="accordin reveal">
								<details >
									<summary>Winners list</summary>
									<div class="faq__content">
										<p><button class="drawBtn" onClick={this.currentWinnersList}>Show Winners list</button>

										</p>
									</div>
								</details>
							</div>

							{this.state._previousList > 0 ?
								(<div class="lotteryMain">
									<div class='hoverme'>
										<div class="taxT"><span>Previous Winners List</span></div>

										<i></i>
										<i></i>
										<i></i>
										<i></i>
										<i></i>
										<i></i>
										<i></i>
										<i></i>
										<i></i>
										<i></i>
										<i></i>
										<i></i>
										<i></i>
										<i></i>
										<i></i>
										<i></i>
										<i></i>
										<i></i>
										<i></i>
										<i></i>
										<i></i>
										<i></i>
										<i></i>
										<i></i>
										<i></i>
										<i></i>
										<i></i>
										<i></i>
										<i></i>
										<i></i>
										<i></i>
										<i></i>
										<i></i>
										<i></i>
										<i></i>
										<i></i>
										<i></i>
										<i></i>
										<i></i>
										<i></i>
										<i></i>
										<i></i>
										<i></i>
										<i></i>
										<i></i>
										<i></i>
										<i></i>
										<i></i>
										<i></i>
										<i></i>
									</div>

									<div class="platformMain2">
										<table id="customers2">
											<tr>
												<th>Address</th>
												<th>Ticket No:</th>
												<th>Prizes</th>
											</tr>
											{this.state._previousRecords.map((element) => {
												return (
													<tr>
														<td>
															{element.walletAddress.slice(0, 12) + "..." + element.walletAddress.slice(30, 42)}
														</td>
														<td>{element.winningTicketNumber}</td>
														<td>{element.prize}</td>
													</tr>
												)
											})}
										</table>

									</div>

								</div>) : null}

							{this.state._cList > 0 ?
								(<div class="lotteryMain">
									<div class='hoverme'>
										<div class="taxT"><span>Winners List</span></div>

										<i></i>
										<i></i>
										<i></i>
										<i></i>
										<i></i>
										<i></i>
										<i></i>
										<i></i>
										<i></i>
										<i></i>
										<i></i>
										<i></i>
										<i></i>
										<i></i>
										<i></i>
										<i></i>
										<i></i>
										<i></i>
										<i></i>
										<i></i>
										<i></i>
										<i></i>
										<i></i>
										<i></i>
										<i></i>
										<i></i>
										<i></i>
										<i></i>
										<i></i>
										<i></i>
										<i></i>
										<i></i>
										<i></i>
										<i></i>
										<i></i>
										<i></i>
										<i></i>
										<i></i>
										<i></i>
										<i></i>
										<i></i>
										<i></i>
										<i></i>
										<i></i>
										<i></i>
										<i></i>
										<i></i>
										<i></i>
										<i></i>
										<i></i>
									</div>

									<div class="platformMain2">
										<table id="customers2">
											<tr>
												<th>Address</th>
												<th>Ticket No:</th>
												<th>Prizes</th>
											</tr>
											{this.state._cRecords.map((element) => {
												return (
													<tr>
														<td>
															{element.walletAddress.slice(0, 12) + "..." + element.walletAddress.slice(30, 42)}
														</td>
														<td>{element.winningTicketNumber}</td>
														<td>{element.prize}</td>
													</tr>
												)
											})}
										</table>

									</div>

								</div>) : null}

						</div>
					</Element>

					<Community />

				</div>
			</div >)
	}
}

export default LotteryPOMG;