import React, { Component, useEffect } from 'react';
import '../App.css';
import "aos/dist/aos.css";
import Aos from 'aos';
import alien from '../assets/alien.jfif';
import note from '../assets/notes.png';

const Stry = () => {
    useEffect(() => {
        Aos.init({ duration: 4000 });
    }, [])
}

class NFT extends Component {

    render() {
        return (

            <div class="boxWrap2Tokenomic">

                <p class="teamH">NFT Dapp minting & Unique NFT</p>
                <p class="storyH2">POM LOTTERY UNIQUE NFT</p>

                <div class="platformMain">

                    <div class="nftCon">
                        <p class="nftT">What is the NFT?</p>
                        <p class="nftT2">A non-fungible token (NFT) can be a financial security consisting of digital data stored on a blockchain.</p>
                        <p class="nftT2">The ownership of an NFT is recorded in the blockchain, and can be transferred by the owner, allowing NFTs to be sold & trades. NFTs can be created by anybody & require few or no coding skills to be create and minted on a platform. NFT typically contain references to digital files such as photo, video, and audio. Because NFT are uniquely identifiable assets, they are difference from Cryptocurrencies, which are fungible.</p>
                        <p class="nftT2">Proponents of NFTs will argue that they provide a public certificate of authenticity or proof of ownership.</p>
                    </div>

                    <table id="customers4">
                        <tr>
                            <th>No</th>
                            <th>NFT Launching Step</th>
                            <th>Price in USD</th>
                            <th>Utilities</th>
                            <th>No.Of NFT</th>
                            <th>NFT Price in POM</th>

                        </tr>
                        <tr>
                            <td>1</td>
                            <td>MeMes Show</td>
                            <td>4.50</td>
                            <td>Full Options</td>
                            <td>10,000</td>
                            <td>200</td>
                        </tr>
                        <tr>
                            <td>2</td>
                            <td>Coconut Tree Show</td>
                            <td>9.00</td>
                            <td>Full Options</td>
                            <td>10,000</td>
                            <td>400</td>
                        </tr>
                        <tr>
                            <td>3</td>
                            <td>POM Show</td>
                            <td>22.50</td>
                            <td>Full Options</td>
                            <td>10,000</td>
                            <td>1,000</td>
                        </tr>
                        <tr>
                            <td>4</td>
                            <td>POM Show</td>
                            <td>45.00</td>
                            <td>Full Options</td>
                            <td>100,000</td>
                            <td>2,000</td>
                        </tr>
                        <tr>
                            <td> </td>
                            <td> </td>
                            <td> </td>
                            <td> </td>
                            <td>130,000</td>
                            <td> </td>
                        </tr>

                    </table>
                </div>

                <div class="taxMain">

                    <div class="taxT">NFT Mint Information</div>

                    <div class="platformMain2">

                        <img class="alien" src={alien} />
                         <div class="infoMain">
                            <div class="tableCon3">
                                <p class="nftInfoT">Launching on December 22nd 2022 time 12.00pm UTC</p>
                                <p>
                                     <p class="nftInfo"><img class="note" src={note} /> Meme Show Unique NFT - 10,000 NFT</p>
                                    <p class="nftInfo"><img class="note" src={note} /> Price - 200 POM/NFT</p>
                                    <p class="nftInfo"><img class="note" src={note} /> Max Wallet - 50 NFT</p>
                                </p>
                            </div>
                            <div class="tableCon3">
                                <p class="nftInfoT">Fund Raise from NFT mint will be used as below:</p>
                                <p>
                                    <p class="nftInfo"><img class="note" src={note} /> 30% will be add to POM Lottery liquidity pool & burn</p>
                                    <p class="nftInfo"><img class="note" src={note} /> 30% will be used for the development</p>
                                    <p class="nftInfo"><img class="note" src={note} /> 20% will be used for the initial marketing</p>
                                    <p class="nftInfo"><img class="note" src={note} /> 20% will be used for the team work</p>
                                </p>
                            </div>
                        </div>

                    </div>
                </div>

            </div>
        )
    }
}

export default NFT;

